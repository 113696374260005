 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
   import router from "@/router"; 
  const $ = require('jquery');

 extend('required', required);


 @Component({
  components: {
    ValidationProvider,
    ValidationObserver  
  },
  })
  export default class appPg2Form extends Vue {
      private user: any = {};
      private states: any = [];
      private Documents: any = {};
     private errorMsg : string = '';
       
      private contentHTML: string = "";
      private isLoading: Boolean = true;
      private validationMsg : string = '';
      private openDialog: Boolean = false ;
      private dialogTitle : string = '';

      private appId : number = 0;   
      private fileBackgroundId:  number = 0;    
      private fileBackgroundContent: string = "";
      private fileBackgroundContentType: string = "";
      private fileBackgroundFileName: string = "";
      private fileBackgroundErrormsg: string = "";
      private fileBackgroundError: boolean = false;
      private fileBackgroundDocType: number = 6;

      private fileVendorId: number = 0; 
      private fileVendorContent: string = "";
      private fileVendorContentType: string = "";
      private fileVendorFileName: string = "";
      private fileVendorErrormsg: string = "";
      private fileVendorError: boolean = false;
      private fileVendorDocType: number = 1;

      private fileFinancialId: number = 0;
      private fileFinancialContent: string = "";
      private fileFinancialContentType: string = "";
      private fileFinancialFileName: string = "";
      private fileFinancialErrormsg: string = "";
      private fileFinancialError: boolean = false;
      private fileFinancialDocType: number = 2;
      
      private fileEquipId: number = 0;
      private fileEquipContent: string = "";
      private fileEquipContentType: string = "";
      private fileEquipFileName: string = "";
      private fileEquipErrormsg: string = "";
      private fileEquipError: boolean = false;
      private fileEquipDocType: number = 4;
      private IsDocumentsCompleted : boolean = false;
      private isSubmitted : boolean = false;
      private appRedirect : string = "";
      private isAppCompleted: Boolean = false;

      private app: any = {};
      private seasonal: any = {};

      private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
      };

      private async created() {
           //page loaded, do stuff here          
           this.user = JSON.parse(storage.local.getItem("user") || '{}'); 
          
            const Response = await api.getSiteContent("APPLICATON_DOCUMENTS");
            this.contentHTML = Response.content;
           
            //load documents 
            this.appId = parseInt(this.$route.params.id, 10);
            this.app = await api.getApplication(this.appId, this.user.id); 
            if (this.app.isSubmitted == true) {
               this.dialogTitle = "Your application has already been submitted!";
               this.validationMsg = ("<p>Our distributions are done on a quarterly basis and approved or denied by the Foundation's Board of Directors. All applicants will be considered and notified after distributions are made whether support will be granted.</p>");
               this.openDialog = true; 
            } 

            this.LoadDocuments();           
            this.isLoading = false;
      }  


      private async SubmitClicked() {
           this.appLimit = await api.getSeasonalLimit(); 
           if(this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false){
               $('#form-limit-dialog').modal('show');
               return;
            }

          if (!this.isFormValid()) 
            return
            $('#form-confirm-dialog').modal('show'); 
          
      }

      private async SaveClicked() {
          if (!this.isFormValid()) 
            return           
          this.PersistSave(false, false);          
      }

      private async LoadDocuments(){
          if (this.appId <= 0) {
            return;
          }
          const appDocs = await api.getApplicationDocuments(this.appId, this.user.id);   
          if (appDocs && appDocs.documents) {
              for (var i = 0; i < appDocs.documents.length; i++) {
                 //add the background document
                 if (appDocs.documents[i].documentType == this.fileBackgroundDocType ) {
                    this.fileBackgroundFileName = appDocs.documents[i].fileName;
                    this.fileBackgroundContent = appDocs.documents[i].content;
                    this.fileBackgroundContentType = appDocs.documents[i].contentType;                     
                 }    
                 //add the vendor document
                 if (appDocs.documents[i].documentType == this.fileVendorDocType) {
                    this.fileVendorFileName = appDocs.documents[i].fileName;
                    this.fileVendorContent = appDocs.documents[i].content;
                    this.fileVendorContentType = appDocs.documents[i].contentType;                        
                 }                              
                 //add the Financial document
                 if (appDocs.documents[i].documentType == this.fileFinancialDocType) {
                    this.fileFinancialFileName = appDocs.documents[i].fileName;
                    this.fileFinancialContent = appDocs.documents[i].content;
                    this.fileFinancialContentType = appDocs.documents[i].contentType;                        
                 }     
                 //add the Equip document
                 if (appDocs.documents[i].documentType == this.fileEquipDocType) {
                    this.fileVendorId = appDocs.documents[i].Id;
                    this.fileEquipFileName = appDocs.documents[i].fileName;
                    this.fileEquipContent = appDocs.documents[i].content;
                    this.fileEquipContentType = appDocs.documents[i].contentType;                        
                 }               
              }
              this.IsDocumentsCompleted = appDocs.application.isDocumentComplete;
              this.isSubmitted = appDocs.application.isSubmitted;
          }
      }

      private async PersistSave(redirectNext: any, isSubmit: any) {
        
        if (isSubmit == true) {
          this.seasonal = await api.getSeasonalLimit();
          if (this.seasonal) {
            if (this.seasonal.limitCapMet == true || this.seasonal.seasonOpen == false) {
                this.validationMsg = (this.seasonal.limitCapMet ? this.seasonal.seasonalMessage + "<p>Your application cannot be submitted at this time.</p>" : "<h3>Application Season is Closed.</h3>");
                this.dialogTitle = "Application Not Submitted!";
                this.openDialog = true;   
                return          
            }
          } 

           //Check if the application is completed
          this.isAppCompleted = (this.app.isApplicantComplete && this.app.isDocumentComplete && this.app.isRequestComplete &&  this.app.isFranchiseComplete  ?  true : false    )
          if(this.isAppCompleted == false) {
              this.dialogTitle = "Application is not Completed!";
              this.validationMsg = "Please complete the following sections before submitting: <br> <ul>";
              this.validationMsg += (this.app.isApplicantComplete == false ? '<li>Applicant and Department </li>' : "");
              this.validationMsg += (this.app.isDocumentComplete == false ? '<li>Required Documents</li>' : "");
              this.validationMsg += (this.app.isRequestComplete == false ? '<li>Request Information</li>' : "");             
              this.validationMsg += (this.app.isFranchiseComplete == false ? '<li>Franchise Information</li>' : "");        
              this.validationMsg += "</ul>";      
              this.openDialog = true;  
              return;
          } 

           //TODO: Might need one more check here - IF USER already has a submitted application for the season - DONT ALLOW SUBMIT
           //if rule is not to allow multiple submitted apps within a season
        }         

         if (!this.isFormValid()) 
            return

          var appDocs = {
             documents : [{}],
             application : {
               appId : 0,
               isDocumentCompleted: false,
               isSubmitted: isSubmit
             }             
          }

          //add the background document
          var doc1 = {
             Id : this.fileBackgroundId,
             documentType : this.fileBackgroundDocType,      
             fileName : this.fileBackgroundFileName,
             Content : this.fileBackgroundContent,
             ContentType : this.fileBackgroundContentType
          }
          doc1.documentType = this.fileBackgroundDocType;
          doc1.fileName = this.fileBackgroundFileName;
          doc1.Content = (this.fileBackgroundContent.split("base64,")[1] == null ? this.fileBackgroundContent : this.fileBackgroundContent.split("base64,")[1] );
          doc1.ContentType = this.fileBackgroundContentType;
          appDocs.documents.push(doc1);

          //add the vendor document
          var doc2 = {
             Id : this.fileVendorId,
             documentType : this.fileVendorDocType,    
             fileName : this.fileVendorFileName,
             Content : (this.fileVendorContent.split("base64,")[1] == null ? this.fileVendorContent : this.fileVendorContent.split("base64,")[1] ),
             ContentType : this.fileVendorContentType
          }  
          appDocs.documents.push(doc2);

          //add the Financial document
          var doc3 = {
             Id : this.fileFinancialId,
             documentType : this.fileFinancialDocType,    
             fileName : this.fileFinancialFileName,
             Content : (this.fileFinancialContent.split("base64,")[1] == null ? this.fileFinancialContent : this.fileFinancialContent.split("base64,")[1] ),
             ContentType : this.fileFinancialContentType
          }            
          appDocs.documents.push(doc3);

           //add the Equipment document
            var doc4 = {
             Id : this.fileFinancialId,
             documentType : this.fileEquipDocType,    
             fileName : this.fileEquipFileName,
             Content : (this.fileEquipContent.split("base64,")[1] == null ? "" : this.fileEquipContent.split("base64,")[1] ),
             ContentType : this.fileEquipContentType
          }   
          appDocs.documents.push(doc4);
          appDocs.documents.shift();
          appDocs.application.appId = this.appId;

          //create the document model / application Id
          try {
            this.app.userId = this.user.id;
            const appResponse = await api.saveApplicationDocumentsCA(appDocs);               
            if (appResponse) {               
                 if (appResponse.application.isDocumentComplete == true && appResponse.application.isSubmitted){      
                       this.IsDocumentsCompleted = appResponse.application.isDocumentComplete;
                       if (redirectNext == true) {
                          router.push({name: "ApplicationConfirmCA", params: { id: this.$route.params.id } });                          
                       } 
                       else {
                          //Need to show Toaster save completed 
                          $('#toast-success-msg').html('Documents saved successfully!'); 
                          $('#toast-success').removeClass('d-none').toast('show'); 
                       }             
                  }
                  else {
                     $('#toast-success').removeClass('d-none').toast('show');     
                  }   
            }   
          } catch (e) {
                   $('#toast-success').removeClass('d-none').toast('show');            
          }             
          
      }

       private async dialogOkClicked() {
          if(this.isAppCompleted == false && this.app.isSubmitted == false) { 
              this.appRedirect = (this.app.isFranchiseComplete == false ? 'ApplicationFranchiseCA' : this.appRedirect);             
              this.appRedirect = (this.app.isRequestComplete == false ? 'ApplicationRequestCA' : this.appRedirect);
              this.appRedirect = (this.app.isDocumentComplete == false ? 'ApplicationDocumentsCA' : this.appRedirect);        
              this.appRedirect = (this.app.isApplicantComplete == false ? 'ApplicationStartCA' : this.appRedirect);    
              router.push({name: this.appRedirect, params: { id: this.$route.params.id } });               
          } 
          else {
            router.push({name: "LoginConfirm" });    
          }
      }

      private async BackClicked() {
          router.push({name: "ApplicationFranchiseCA", params: { id: this.$route.params.id } });       
      }

      private confirmNextClicked() {  
           //submit the application here
           this.PersistSave(true, true);
      }

      private isFormValid() {
         var bValid = true;
         if(this.fileBackgroundFileName.length <= 0 ){
            this.fileBackgroundError = true;
            this.fileBackgroundErrormsg = "Background/History is required!";             
            $('input[name="fileBackgroundFileName"]').addClass("is-invalid");
            $('input[name="fileBackgroundFileName"]').focus();
            return false;
          }
          else {
            this.fileBackgroundError = false;
            this.fileBackgroundErrormsg = "";           
            $('input[name="fileBackgroundFileName"]').removeClass("is-invalid");            
          }

          if(this.fileVendorFileName.length <= 0 ){
            this.fileVendorError = true;
            this.fileVendorErrormsg = "Vendor Equipment Quote/Bid is required!";             
            $('input[name="fileVendorFileName"]').addClass("is-invalid");
            $('input[name="fileVendorFileName"]').focus();
            return false;
          }
          else {
            this.fileVendorError = false;
            this.fileVendorErrormsg = "";           
            $('input[name="fileVendorFileName"]').removeClass("is-invalid");             
          }

          if(this.fileFinancialFileName.length <= 0 ){
            this.fileFinancialError = true;
            this.fileFinancialErrormsg = "Most Recent Financial Information is required!";             
            $('input[name="fileFinancialFileName"]').addClass("is-invalid");
            $('input[name="fileFinancialFileName"]').focus();
            return false;
          }
          else {
            this.fileFinancialError = false;
            this.fileFinancialErrormsg = "";           
            $('input[name="fileFinancialFileName"]').removeClass("is-invalid");             
          }     

          if(this.fileEquipFileName.length <= 0 ){
            this.fileEquipError = true;
            this.fileEquipErrormsg = "Equipment Inventory is required!";             
            $('input[name="fileEquipFileName"]').addClass("is-invalid");
            $('input[name="fileEquipFileName"]').focus();
            return false;
          }
          else {
            this.fileEquipError = false;
            this.fileEquipErrormsg = "";           
            $('input[name="fileEquipFileName"]').removeClass("is-invalid");             
          }

          return bValid;
      }

      private async handleBackgroundFileChange(e: any) {
         var files =  e.target.files;
         if (!files.length)
           return;

          const fsize = files[0].size;
          const file = Math.round((fsize / 1024));
          if (file > 20000) {  
            e.preventDefault();
            this.fileBackgroundError = true;
            this.fileBackgroundFileName = "";
            this.fileBackgroundContentType = ""
            this.fileBackgroundErrormsg = "File Size Invalid.. Please select a file with less than 20MB in size."
            return;
          }
          //check file types ext:jpg,doc,docx,jpeg,xlsx
           if(!files[0].type.includes('jpg')&&!files[0].type.includes('png')&&!files[0].type.includes('jpeg')&&!files[0].type.includes('doc')&&!files[0].type.includes('xls')&&!files[0].type.includes('pdf')){
             e.preventDefault();
              this.fileBackgroundError = true;
              this.fileBackgroundFileName = "";
              this.fileBackgroundContentType = ""
              this.fileBackgroundErrormsg = "File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX)."
              $('input[name="fileBackgroundFileName"]').addClass("is-invalid");
              return;
          }         
          this.fileBackgroundFileName = files[0].name;
          this.fileBackgroundContentType = files[0].type;
          this.fileBackgroundError = false;
          this.fileBackgroundErrormsg = "";  
           $('input[name="fileBackgroundFileName"]').removeClass("is-invalid");      
          // Pass getBuffer to promise.
          this.readFileData(files[0], "Background"); 
      }

       private async handleVendorFileChange(e: any) {
         var files =  e.target.files;
         if (!files.length)
           return;

          const fsize = files[0].size;
          const file = Math.round((fsize / 1024));
          if (file > 20000) {  
            e.preventDefault();
            this.fileVendorError = true;
            this.fileVendorFileName = "";
            this.fileVendorContentType = ""
            this.fileVendorErrormsg = "File Size Invalid.. Please select a file with less than 20MB in size."
            return;
          }
          //check file types ext:jpg,doc,docx,jpeg,xlsx
           if(!files[0].type.includes('jpg')&&!files[0].type.includes('png')&&!files[0].type.includes('jpeg')&&!files[0].type.includes('doc')&&!files[0].type.includes('xls')&&!files[0].type.includes('pdf')){
             e.preventDefault();
              this.fileVendorError = true;
              this.fileVendorFileName = "";
              this.fileVendorContentType = ""
              this.fileVendorErrormsg = "File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX)."              
              $('input[name="fileVendorFileName"]').addClass("is-invalid");
              return;
          }         
          this.fileVendorFileName = files[0].name;
          this.fileVendorContentType = files[0].type;
          this.fileVendorError = false;
          this.fileVendorErrormsg = "";  
          $('input[name="fileVendorFileName"]').removeClass("is-invalid");      
          // Pass getBuffer to promise.
          this.readFileData(files[0], "Vendor"); 
      }

      private async handleEquipFileChange(e: any) {
         var files =  e.target.files;
         if (!files.length)
           return;

          const fsize = files[0].size;
          const file = Math.round((fsize / 1024));
          if (file > 20000) {  
            e.preventDefault();
            this.fileEquipError = true;
            this.fileEquipFileName = "";
            this.fileEquipContentType = ""
            this.fileEquipErrormsg = "File Size Invalid.. Please select a file with less than 20MB in size."
            return;
          }
          //check file types ext:jpg,doc,docx,jpeg,xlsx
          if(!files[0].type.includes('jpg')&&!files[0].type.includes('png')&&!files[0].type.includes('jpeg')&&!files[0].type.includes('doc')&&!files[0].type.includes('xls')&&!files[0].type.includes('pdf')){
             e.preventDefault();
              this.fileEquipError = true;
              this.fileEquipFileName = "";
              this.fileEquipContentType = ""
              this.fileEquipErrormsg = "File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX)."              
              $('input[name="fileEquipFileName"]').addClass("is-invalid");
              return;
          }         
          this.fileEquipFileName = files[0].name;
          this.fileEquipContentType = files[0].type;
          this.fileEquipError = false;
          this.fileEquipErrormsg = "";  
           $('input[name="fileEquipFileName"]').removeClass("is-invalid");      
          // Pass getBuffer to promise.
          this.readFileData(files[0], "Equip"); 
      }

      private async handleFinancialFileChange(e: any) {
         var files =  e.target.files;
         if (!files.length)
           return;

          const fsize = files[0].size;
          const file = Math.round((fsize / 1024));
          if (file > 20000) {  
            e.preventDefault();
            this.fileFinancialError = true;
            this.fileFinancialFileName = "";
            this.fileFinancialContentType = ""
            this.fileFinancialErrormsg = "File Size Invalid.. Please select a file with less than 20MB in size."
            return;
          }
          //check file types ext:jpg,doc,docx,jpeg,xlsx
           if(!files[0].type.includes('jpg')&&!files[0].type.includes('png')&&!files[0].type.includes('jpeg')&&!files[0].type.includes('doc')&&!files[0].type.includes('xls')&&!files[0].type.includes('pdf')){
             e.preventDefault();
              this.fileFinancialError = true;
              this.fileFinancialFileName = "";
              this.fileFinancialContentType = ""
              this.fileFinancialErrormsg = "File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX)."              
              $('input[name="fileFinancialFileName"]').addClass("is-invalid");
              return;
          }         
          this.fileFinancialFileName = files[0].name;
          this.fileFinancialContentType = files[0].type;
          this.fileFinancialError = false;
          this.fileFinancialErrormsg = "";  
           $('input[name="fileFinancialFileName"]').removeClass("is-invalid");      
          // Pass getBuffer to promise.
          this.readFileData(files[0], "Financial"); 
      }

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }

      private async readFileData(file: any, type : any) {
        let reader = new FileReader();
          reader.readAsDataURL(file);    
          reader.onload = (e: any) => { 
            if (type == "Background") {
              this.fileBackgroundContent = e.target.result;
            }          
            if (type == "Vendor") {
              this.fileVendorContent = e.target.result;
            } 
            if (type == "Equip") {
              this.fileEquipContent = e.target.result;
            }  
            if (type == "Financial") {
              this.fileFinancialContent = e.target.result;
            }                 
          };  
          reader.onerror = evt => {
            //console.error(evt); //show an alert message here
          }
      }
     

  }
  
