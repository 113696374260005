 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import storage from '@/plugins/storage'
  import router from "@/router";
  const $ = require('jquery');

  @Component
  export default class LoginForm extends Vue {

      private userEmail: string = '';
      private userPassword: string = '';      
      private loginError: string = ''; 
      private loginResult: any = {};
      private appRedirect : string = '';
      private downloadError : string = '';
      private user: any = {}; 
      
      private apps: any = [{        
          appId : 0
         ,grantRequestTypeDisplay : ''
         ,organization : ''
         ,statusId : 0
         ,createDt : null
         ,activeDt : null
         ,isPrintVideoAccepted: false
         ,isApplicantComplete: false
         ,isDocumentComplete: false
         ,isRequestComplete: false
         ,isCommunityComplete: false
         ,isFranchiseComplete : false
         ,isComplete : false
         ,isSubmitted : false
      }];    
      
       private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
      };
     
      private async created() {
         //page loaded, do stuff here    
         this.user = JSON.parse(storage.local.getItem("user") || '{}');

         this.appLimit = await api.getSeasonalLimit(); 
            if(this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false){
               $('#form-limit-dialog').modal('show');
               return;
            }
         
         //Get app list
         this.apps = await api.getActiveApplications(this.user.email);   
         if (this.apps.length <= 0 ) {           
               router.push({name: ( process.env.VUE_APP_ENVIRON == 'CA' ? 'ApplicationStartCA' : 'ApplicationStart'),  params: { id: 'new' } });  
         }        
      }   

     
      private async redirectHome() {
          router.push({path: "/" }).catch(e => {}); 
      }
     
     private async editApplication(row: any) {
          if(row.isComplete == false && process.env.VUE_APP_ENVIRON != 'CA') { 
            //old code for going to last page not completed.
              // this.appRedirect = (row.isPrintVideoAccepted == false ? 'ApplicationRelease' : this.appRedirect);  //IsPrintVideoAccepted 
              // this.appRedirect = (row.isFranchiseComplete == false ? 'ApplicationFranchise' : this.appRedirect);
              // this.appRedirect = (row.isCommunityComplete == false ? 'ApplicationCommunity' : this.appRedirect);
              // this.appRedirect = (row.isRequestComplete == false ? 'ApplicationRequest' : this.appRedirect);
              // this.appRedirect = (row.isDocumentComplete == false ? 'ApplicationDocuments' : this.appRedirect);        
              // this.appRedirect = (row.isApplicantComplete == false ? 'ApplicationStart' : this.appRedirect); 
              this.appRedirect = 'ApplicationStart';
              router.push({name: this.appRedirect, params: { id: row.appId } }).catch(e => {});     
          }
          else {              
              this.appRedirect = (row.isFranchiseComplete == false ? 'ApplicationFranchiseCA' : this.appRedirect);            
              this.appRedirect = (row.isRequestComplete == false ? 'ApplicationRequestCA' : this.appRedirect);
              this.appRedirect = (row.isDocumentComplete == false ? 'ApplicationDocumentsCA' : this.appRedirect);        
              this.appRedirect = (row.isApplicantComplete == false ? 'ApplicationStartCA' : this.appRedirect); 
              router.push({name: this.appRedirect, params: { id: row.appId } }).catch(e => {});     
          }
     } 

     private async viewPDF(row: any) {
        try {
            //only works in CHROME AND FIREFOX        
            var nAgt = navigator.userAgent;            
            var filecontentURL =  process.env.VUE_APP_API_URL + "/api/app/pdf?appId=" + row.appId + "&userId=" + this.user.id; //await api.getAppPDF(this.appId);  
            
            var a = window.document.createElement('a');
            a.href = filecontentURL;
            a.download = "Application.pdf";
            // Append anchor to body.
            document.body.appendChild(a);
            a.click();
            // Remove anchor from body
            document.body.removeChild(a);    
        }
         catch(ex) {
           this.downloadError = "Unexpected Error Occured while attempting to download the application PDF, please contact support.";
        }     
        

     } 

  }

  
