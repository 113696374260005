
import { Component, Vue } from 'vue-property-decorator';
import api from '@/plugins/api';
import router from '@/router';
const $ = require('jquery');

@Component
export default class GuideForm extends Vue {
  private contentHTML: string = '';

  private async created() {
    const Response = await api.getSiteContent('APPLICATION_START_GUIDELINES');
    //debugger;
    console.log(Response);
    this.contentHTML = Response.content;
  }

  private async NextClicked() {
    //redirect back to dashboard
    router.push({ path: '/register' }).catch((e) => {});
  }
}
