import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";

Vue.use(VueRouter);

import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AdminLayout from "@/views/Layout/AdminLayout.vue";

import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import UserProfile from "@/views/UserProf.vue";
import FAQ from "@/views/FAQ.vue";
import Login from "@/views/Login.vue";
import LoginConfirm from "@/views/LoginConfirm.vue";
import PasswordReset from "@/views/LoginReset.vue";
import ForgotPassword from "@/views/LoginForgotPassword.vue";
import Register from "@/views/Register.vue";
import PreQualify from "@/views/PreQualify.vue";
import PreQualifyCA from "@/views/PreQualifyCA.vue";
import WelcomeAppStart from "@/views/Welcome.vue";
import GuideAppStart from "@/views/Guidelines.vue";
import LegalNotice from "@/views/LegalNotice.vue";
import Privacy from "@/views/Privacy.vue";
import PageNotFound from "@/views/404Error.vue";

//Admin
import Admin from "@/views/Admin/ApplicationList.vue";
import AdminApplication from "@/views/Admin/Application.vue";
import AdminEditFAQ from "@/views/Admin/ManageContentFaq.vue";
import AdminEditAppContent from "@/views/Admin/ManageContentApp.vue";
import AdminAppSchedule from "@/views/Admin/Schedule.vue";
import AdminAreaReps from "@/views/Admin/ARList.vue";

//Application
import Application from "@/views/Application.vue";
import ApplicationStartPage1 from "@/views/Application/ApplicationStart.vue";
import ApplicationDocuments from "@/views/Application/ApplicationDocuments.vue";
import ApplicationRequest from "@/views/Application/ApplicationRequest.vue";
import ApplicationCommunity from "@/views/Application/ApplicationCommunity.vue";
import ApplicationFranchise from "@/views/Application/ApplicationFranchise.vue";
import ApplicationRelease from "@/views/Application/ApplicationPrintRelease.vue";
import ApplicationConfirm from "@/views/Application/ApplicationConfirm.vue";

//Application Canada
import ApplicationCA from "@/views/Application.vue";
import ApplicationStartPage1CA from "@/views/ApplicationCA/ApplicationStart.vue";
import ApplicationDocumentsCA from "@/views/ApplicationCA/ApplicationDocuments.vue";
import ApplicationRequestCA from "@/views/ApplicationCA/ApplicationRequest.vue";
import ApplicationFranchiseCA from "@/views/ApplicationCA/ApplicationFranchise.vue";
import ApplicationConfirmCA from "@/views/ApplicationCA/ApplicationConfirm.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    redirect: "/",
    children: [
      {
        path: "/dashboard",
        name: "Welcome",
        redirect: "/",
        component: Dashboard
      },
      {
        path: "/user",
        name: "User Profile",
        component: UserProfile
      },
      {
        path: "/faq",
        name: "Applicant FAQs",
        component: FAQ
      },
      {
          path: "/login",
          name: "Login",
          component: Login
      },
      {
          path: "/my-apps",
          name: "LoginConfirm",
          component: LoginConfirm
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword
     },
     {
      path: "/password-reset/:token",
      name: "PasswordReset",
      component: PasswordReset
     },
    
      {
          path: "/register",
          name: "Register",
          component: Register
      },
      {
          path: "/prequalify",
          name: "Prequalification",
          component: PreQualify
      },    
      {
        path: "/prequalify-ca",
        name: "PrequalificationCA",
        component: PreQualifyCA
      },       
      //Application CA
      {
          path: "/application-ca/:id", name: "ApplicationCA",  component: ApplicationCA,  
          redirect: "/application-ca/:id/applicant",        
          children: [         
            {
               path: 'applicant', component: ApplicationStartPage1CA, name: "ApplicationStartCA"
            },
            {
              path: 'documents',  component: ApplicationDocumentsCA, name: "ApplicationDocumentsCA"
            },
            {
              path: 'request',  component: ApplicationRequestCA, name: "ApplicationRequestCA"
             },            
             {
              path: 'franchise',  component: ApplicationFranchiseCA, name: "ApplicationFranchiseCA"
             },            
             {
              path: 'confirm',  component: ApplicationConfirmCA, name: "ApplicationConfirmCA"
             },
          ]        
      },     
      {
        path: "/application/:id", name: "Application",  component: Application,  
        redirect: "/application/:id/applicant",        
        children: [         
          {
             path: 'applicant', component: ApplicationStartPage1, name: "ApplicationStart"
          },
          {
            path: 'documents',  component: ApplicationDocuments, name: "ApplicationDocuments"
          },
          {
            path: 'request',  component: ApplicationRequest, name: "ApplicationRequest"
           },
           {
            path: 'community',  component: ApplicationCommunity, name: "ApplicationCommunity"
           },
           {
            path: 'franchise',  component: ApplicationFranchise, name: "ApplicationFranchise"
           },
           {
            path: 'release',  component: ApplicationRelease, name: "ApplicationRelease"
           },
           {
            path: 'confirm',  component: ApplicationConfirm, name: "ApplicationConfirm"
           },
        ]        
      },
      {
          path: "/welcome",
          name: "Welcome",
          component: WelcomeAppStart 
      },
      {
          path: "/guidelines",
          name: "Guidelines",
          component: GuideAppStart 
      },
      {
          path: "/privacy",
          name: "PrivacyPolicy",
          component: Privacy
      },
      {
          path: "/legal",
          name: "Legal",
          component: LegalNotice 
      }
    ]
  },   
  {
    path: "/admin",
    name: "Admin",
    component: AdminLayout,
    redirect: "/admin/applications",
    children: [
      {
         path: 'applications', component: Admin, name: "Admin"
      },
      {
        path: 'application/:id', component: AdminApplication, name: "AdminApplicaton"
      },
      {
        path: 'manage-faq-policy-legal', component: AdminEditFAQ, name: "AdminEditFAQ"
      },
      {
        path: 'manage-app-content', component: AdminEditAppContent, name: "AdminEditAppContent"
      },
      {
        path: 'schedule', component: AdminAppSchedule, name: "AdminAppSchedule"
      },
      {
        path: 'area-reps', component: AdminAreaReps, name: "AdminAreaReps"
      }
    ]        
  },
  
 

 { path: "*", component: PageNotFound }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "nav-item active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;

router.beforeEach((to: any, from: any, next: any) => {
  document.title = process.env.VUE_APP_TITLE;  //to.meta.title
  /*
    meta: {
        auth: false,
        title: 'Login'
      }
  */
  next();
});
