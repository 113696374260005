
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/plugins/storage';
import api from '@/plugins/api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import SmartTable from 'vuejs-smart-table';
import router from '../../router';
import excel from 'vue-excel-export';
import { jsPDF } from 'jspdf';

const $ = require('jquery');

extend('required', required);
Vue.use(excel);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class appForm extends Vue {
  private user: any = {};
  private states: any = [];
  private Documents: any = {};
  private errorMsg: string = '';
  private searchText: string = '';
  private downloadError: string = '';

  private currentQtr: number = 0;
  private params: any = {};
  private data: any = [];
  private yearList: any = [];
  private qtrList: any = [];
  private statusList: any = [];
  private dmaList: any = [];
  private opts: any = {};
  private isLoading: Boolean = true;
  private currentPage: number = 1;
  private totalPages: number = 10;
  private pageSize: number = 10;
  private pageSizeSelected: string = '10';
  private filters: any = {
    grantStatus: { value: '', keys: ['grantStatus'] },
    year: { value: this.getQuarterAndYear().year, keys: ['year'] },
    qtr: { value: this.getQuarterAndYear().quarter, keys: ['qtr'] },
    dept: { value: '', keys: ['organization'] },
    all: { value: '', keys: ['grantStatus', 'organization', 'donationAmtDisplay', 'equipment', 'applicantDate', 'franchisee', 'contactForm', 'arSupport', 'areaRep', 'dmaCode'] },
  };

  private async viewDocument(appId: number) {
    try {
      //only works in CHROME AND FIREFOX
      var nAgt = navigator.userAgent;
      var filecontentURL = process.env.VUE_APP_API_URL + '/api/app/pdf?appId=' + appId + '&userId=' + this.user.id; //await api.getAppPDF(this.appId);

      var a = window.document.createElement('a');
      a.href = filecontentURL;
      a.download = 'Application.pdf';
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    } catch (ex) {
      this.downloadError = 'Unexpected Error Occured while attempting to download the application PDF, please contact support.';
    }
  }

  private json_fields: any = {
    SubmittedOn: 'applicantDate',
    Applicant: 'applicantFullName',
    ApplicantTitle: 'title',
    ApplicantEmail: 'contactEmail',
    ApplicantCellPhoneNumber: 'applicantCellPhoneNumber',
    GrantType: 'grantRequestTypeDisplay',
    'Department/Organization': 'organization',
    TaxId: 'taxId',
    MailingAddress1: 'mailingAddress1',
    MailingAddress2: 'mailingAddress2',
    City: 'mailingCity',
    State: 'mailingState',
    Zip: 'mailingZip',
    Phone: 'orgPhone',
    PhoneExt: 'orgPhoneExt',
    AltContactName: 'altContactName',
    AltPhoneNumber: 'altContactPhone',
    AltContactEmail: 'altContactEmail',
    CommunityServed: 'commServed',
    Population: 'commPopulation',
    RunsPerYear: 'runsPerYear',
    EquipmentRequested: 'requestInformation',
    Synopsis: 'synopsis',
    TotalDonationAmount: 'donationAmt',
    NumOfTimesApplied: 'nbrOfTimesPrevSubmitted',
    ScholarshipsCount: 'nbrOfScholarships',
    ScholarshipsProcessDescription: 'scholarshipDesc',
    SalesTax: 'SalesTax',
    ShippingAmount: 'ShippingAmount',
    ChildrenImpacted: 'nbrofChildrenImpacted',
    SeniorsImpacted: 'nbrofSeniorsImpacted',
    InstanceOfEquipmentImpact: 'equipmentImpactInstance',
    PositiveEffectsOfEquipment: 'equipmentPositiveEffects',
    // NearestRestaurantAddress: 'nearestRestaurantAddress',
    NearestRestaurantStreet: 'nearestRestaurantStreet',
    NearestRestaurantSuite: 'nearestRestaurantSuite',
    NearestRestaurantCity: 'nearestRestaurantCity',
    NearestRestaurantState: 'nearestRestaurantState',
    NearestRestaurantZip: 'nearestRestaurantZip',
    DistanceToRestaurant: 'distanceToRestaurant',
    LeadSource: 'howDidTheyHear',
    ReceivedPreviousFunding: 'previousFundingIndDisplay',
    AcceptanceInitials: 'acceptanceInitials',
    PIOName: 'pIOName',
    PIOEmail: 'pIOEmail',
    PIOPhone: 'pIOPhone',
    Status: 'grantStatus',
    DMA: 'dma',
    'Contact Form': 'contactForm',
    'AR Support': 'arSupport',
    LocalApproval: 'localApproval',
    'Area Rep': 'areaRep',
    ShippingCity: 'shippingCity',
    ShippingState: 'shippingState',
    ShippingZip: 'shippingZip',
    ShippingAddress: 'shippingAddress1',
    ShippingAddress2: 'shippingAddress2',
    VendorCompanyName: 'vendorCompanyName',
    SalesRepresentativeFullName: 'salesRepName',
    SalesRepresentativeEmail: 'salesRepEmail',
    'Assigned Admin': 'assignedAdminName',
    Notes: 'notes',
    CreatedOn: 'createDt',
    'Last Modified Time': 'updatedOn',
  };

  private yearSelected: string = '';
  private qtrSelected: string = '';
  private statusSelected: string = '';
  private searchDeptsOnly: boolean = false;

  private appId: number = 0;

  private async created() {
    $('#app-loading').fadeIn(1000);
    //page loaded, do stuff here
    this.user = JSON.parse(storage.local.getItem('user') || '{}');
    if (this.user.id == null || this.user.id == undefined) {
      router.push({ name: 'Login' }).catch((e) => {});
    } else {
      if (!this.user.role.includes('ADMIN')) {
        router.push({ name: 'Login' }).catch((e) => {});
      }
    }
    this.data = await api.getAdminApplicationList(0, 0);

    $('#app-loading').fadeOut(1000);

    // this.currentQtr = await api.getCurrentQuarter();

    var d = new Date();
    // this.filters.year.value = d.getFullYear();
    // this.filters.year.value = this.getQuarterAndYear().year;
    // this.filters.qtr.value = this.currentQtr;

    this.getYearList();
    this.qtrList = [1, 2, 3, 4];
    this.statusList = ['Incomplete', 'Complete', 'Approved', 'Denied', 'Alternate', 'Deferred', 'Recommended', 'Not Recommended'];
  }

  private async LoadApplications() {
    $('#app-loading').fadeIn(1000);
    var qtr = parseInt(this.filters.qtr.value);
    var year = parseInt(this.filters.year.value);
    this.data = await api.getAdminApplicationList(qtr, year);
    $('#app-loading').fadeOut(1000);
  }

  private async ExportItem($event: any) {}

  private async ExportAllClicked() {}

  private async ResetClicked($event: any) {
    this.filters.year.value = this.getQuarterAndYear().year;
    this.filters.qtr.value = this.getQuarterAndYear().quarter;
    this.filters.all.value = '';
    this.filters.grantStatus.value = '';
  }

  private async searchDeptsClicked() {
    this.filters.all.value = '';
    this.filters.dept.value = '';
  }

  private async getYearList() {
    var years = [];
    var currentYear = new Date().getFullYear() + 1;
    var startYear = currentYear - 25;
    while (currentYear >= startYear) {
      this.yearList.push(currentYear--);
    }
  }

  private async getExportList() {
    var year = this.filters.year.value;
    var qtr = this.filters.qtr.value;

    if (year === '' || qtr === '') {
      this.showhideAlert(true, 'Can only export with a valid Year and Quarter!', true, 'error');
      return;
    }

    try {
      const response = await api.adminExcelDownload(year, qtr);
      return response;
    } catch (e) {
      this.showhideAlert(true, 'An error occurred while downloading Excel...', false, 'error');
    }
  }

  private async SaveStatusClicked(app: any) {
    this.showhideAlert(true, 'Saving...', false, 'success');

    switch (app.grantStatus) {
      case 'Incomplete':
        app.StatusId = 1;
        break;
      case 'Complete':
        app.StatusId = 2;
        break;
      case 'Approved':
        app.StatusId = 3;
        break;
      case 'Denied':
        app.StatusId = 4;
        break;
      case 'Alternate':
        app.StatusId = 5;
        break;
      case 'Deferred':
        app.StatusId = 6;
        break;
      case 'Recommended':
        app.StatusId = 7;
        break;
      case 'Not Recommended':
        app.StatusId = 8;
        break;
    }

    //make api call to save status
    try {
      await api.saveAppStatus(app);
      //alert
      this.showhideAlert(true, 'Application Status saved successfully!', false, 'success');
    } catch (e) {
      this.showhideAlert(true, 'An error occurred while saving the status...', true, 'error');
    }
  }

  private async changePageSize() {
    this.pageSize = parseInt(this.pageSizeSelected);
  }

  private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any) {
    $('#app-alert').removeClass('d-none');
    if (status == 'error') {
      $('#app-alert').addClass('alert-danger');
      $('#app-alert').removeClass('alert-success');
    } else {
      $('#app-alert').addClass('alert-success');
      $('#app-alert').removeClass('alert-danger');
    }
    if (showAlert == true) {
      if (showLoading == false) {
        $('#app-alert-spinner').addClass('d-none');
      } else {
        $('#app-alert-spinner').removeClass('d-none');
      }
      $('#app-alert-msg').html(msgText);
      $('#app-alert').fadeIn(1000);
      setTimeout(function () {
        $('#app-alert').fadeOut(2000);
      }, 3000);
    } else {
      $('#app-alert').fadeOut(2000);
    }
  }

  private getQuarterAndYear(): { quarter: number; year: number } {
    let d = new Date();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let year = d.getFullYear();

    if (month === 1 && date < 7) {
      return { quarter: 1, year: year };
    } else if (month < 4 || (month === 4 && date < 7)) {
      return { quarter: 2, year: year };
    } else if (month < 7 || (month === 7 && date < 7)) {
      return { quarter: 3, year: year };
    } else if (month < 10 || (month === 10 && date < 6)) {
      return { quarter: 4, year: year };
    } else {
      return { quarter: 1, year: ++year };
    }
  }
}
