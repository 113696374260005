 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import { EventBus } from '@/plugins/eventbus';
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import { CurrencyDirective } from 'vue-currency-input'
  import router from "@/router"; 

  const $ = require('jquery');
   

 extend('required', required);
 extend('email', email);

 @Component({
  components: {
    ValidationProvider,
    ValidationObserver  
  },
  directives : {
     currency: CurrencyDirective
  }
  })
  export default class appReq1Form extends Vue {
      private user: any = {};
      private states: any = [];
      private errorMsg : string = '';
      
      private showGrantType1Questions : boolean = false;
      private showGrantType2Questions : boolean = false;     

      private app: any = {
          //applicant
          appId: '', 
          //request
          grantRequestType :  null,
          isRequestCompleted : false,
          requestInformation:  '',
          vendorCompanyName:  '',
          salesRepName:  '',
          salesRepEmail:  '',
          requestFundingAmt: 0,
          confirmFundingAmt:  '',
          requestDetails: '',  
          childrenSeniorImpactDetails : '', //ca
          acceptFundingVarianceInd: null,
          previousFundingInd : null,
          nbrOfTimesPrevSubmitted :  null, 
          nbrofChildrenImpacted : null,
          nbrofSeniorsImpacted : null,
          nbrOfScholarships : null,
          appliedInThePast : null      
      };    
     
       private async created() {
           //get user           
           this.user = JSON.parse(storage.local.getItem('user') || '{}');       

           //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               this.app = await api.getApplication(id, this.user.id);   
            }    
           
           // Listen for the i-got-clicked event and its payload.
          EventBus.$on('app-request-1-saveclicked', this.SaveClicked);
          EventBus.$on('app-request-1-submitclicked', this.SubmitClicked);
      }  

     public async SubmitClicked(id: number) {
       try {  
          var valid = await (this.$refs.app1Form as Vue & { validate: () => boolean }).validate()                        
          if (!valid) {
              return;
          }

           this.app.requestFundingAmt.replace("$", '').replace(",", '');
           this.app.grantRequestType = 1;

          
            const appResponse = await api.saveApplicationRequest(this.app);               
            if (appResponse) {               
                 if (appResponse.appId <= 0){  
                      $('#toast-success').removeClass('d-none').toast('show');  
                                
                  } 
                   router.push({name: "ApplicationFranchiseCA", params: { id: this.$route.params.id } }); 
            }   
          } catch (e) {      
            $('#toast-success').removeClass('d-none').toast('show');               
          }  
         
      }

      public async SaveClicked(id: number) { 
        try {          
          var valid = await (this.$refs.app1Form as Vue & { validate: () => boolean }).validate()                        
          if (!valid) { 
              return;
          } 

          this.app.requestFundingAmt.replace("$", '').replace(",", '');

           
            const appResponse = await api.saveApplicationRequest(this.app);               
            if (appResponse) {               
                 if (appResponse.appId <= 0){  
                      $('#toast-success').removeClass('d-none').toast('show');                                  
                  } 
                  //display a successful toaster
                  $('#toast-success-msg').html('Request Information saved successfully!'); 
                  $('#toast-success').removeClass('d-none').toast('show'); 
            }   
          } catch (e) {      
            $('#toast-success').removeClass('d-none').toast('show');               
          }  
      }

      private async BackClicked() {          
          router.push({name: "ApplicationStartCA", params: { id: this.$route.params.id } });
      }
 
      private async grantType1Clicked() {
           this.showGrantType1Questions = true;
           this.showGrantType2Questions = false;
      }  

      private async grantType2Clicked() {
           this.showGrantType1Questions = false;
           this.showGrantType2Questions = true;
      }  

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }
     

  }

  
