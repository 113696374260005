import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
//import moment from "moment";
import store from '@/store';
import $get from 'lodash.get';
import storage from '@/plugins/storage';
import { ApplicationForm, MainApplication, Note } from '@/models/main-models';
import moment from 'moment';
import { Methods } from '@/utils/methods';

const uri = process.env.VUE_APP_API_URL;

const api = axios.create({
  baseURL: uri + '/api/', //process.env.VUE_APP_API_URL
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //const isRefresh = $get(config, 'url', '').includes('/token')
    const accessToken = storage.local.getItem('auth/token');
    if (accessToken) {
      config.headers.common['Authorization'] = `Bearer ${accessToken}`;
      config.headers.common['content-type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const iid = api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const accessToken = storage.local.getItem('auth/token');
    const refreshToken = storage.local.getItem('auth/refreshtoken');
    const encodeRefreshToken = refreshToken !== null ? encodeURIComponent(refreshToken) : null;

    if ($get(error, 'response.status') === 401 && accessToken) {
      try {
        // prevent 401 loops
        api.interceptors.response.eject(iid);
        const r = await api.get(`login/token?token=${accessToken}&refreshToken=${encodeRefreshToken}`);

        store.dispatch('auth/setToken', r.data.token);
        store.dispatch('auth/setRefreshToken', r.data.refreshToken);

        error.config.headers.Authorization = `Bearer ${r.data.token}`;

        return api(error.config);
      } catch (e) {
        // revoke token and redirect
        store.dispatch('auth/clearToken');
        storage.local.setItem('user', JSON.stringify('' || {}));
        window.location.assign('/login');
      }
    }
    return Promise.reject(error);
  }
);

export default {
  getUserProfile: async (userId: number) => {
    const r = await api.get(`users/${userId}`);
    return r.data;
  },
  login: async (email: string, password: string) => {
    const encodePassword = password != null ? encodeURIComponent(password) : null;
    const encodeEmail = email != null ? encodeURIComponent(email) : null;
    const r = await api.get(`login/get?UserEmail=${encodeEmail}&UserPassword=${encodePassword}`);
    return r.data;
  },
  logout: async () => {
    await api.post('/logout');
  },
  passwordInitiateReset: async (email: string) => {
    await api.post('users/password/reset/initiate', { emailAddress: email });
  },
  passwordReset: async (token: string, passwordText: string) => {
    await api.post('users/password/reset/update', { passwordText, token });
  },
  getSiteContent: async (name: string) => {
    const r = await api.get(`app/content?name=${name}`);
    return r.data;
  },
  addUser: async (firstName: string, lastName: string, title: string, emailAddress: string, password: string) => {
    const r = await api.post('login/create', {
      Email: emailAddress,
      Title: title,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
    });
    return r.data;
  },

  saveApplicationApplicant: async (app: any) => {
    const r = await api.post('app/save-applicant', app);
    return r.data; //should get an id back for the app, esp. new apps
  },

  getApplication: async (appId: number, userId: number) => {
    const r = await api.get(`app/get-application?appId=${appId}&userId=${userId}`);
    return r.data;
  },

  saveApplicationDocuments: async (app: any) => {
    const r = await api.post('app/save-documents', app);
    return r.data;
  },

  saveApplicationDocumentsCA: async (app: any) => {
    const r = await api.post('app/save-documents-ca', app);
    return r.data;
  },

  getApplicationDocuments: async (appId: number, userId: number) => {
    const r = await api.get(`app/get-documents?appId=${appId}&userId=${userId}`);
    return r.data;
  },

  saveApplicationRequest: async (app: any) => {
    const r = await api.post('app/save-request', app);
    return r.data; //should get an id back for the app, esp. new apps
  },

  saveApplicationCommunity: async (app: any) => {
    const r = await api.post('app/save-community', app);
    return r.data; //should get an id back for the app, esp. new apps
  },

  saveApplicationFranchise: async (app: any) => {
    const r = await api.post('app/save-franchise', app);
    return r.data; //should get an id back for the app, esp. new apps
  },

  saveApplicationPrintRelease: async (app: any) => {
    const r = await api.post('app/save-print-release', app);
    return r.data; //should get an id back for the app, esp. new apps
  },

  saveSiteContent: async (siteContent: any) => {
    const r = await api.post('app/update-content', siteContent);
    return r.data;
  },

  //api/app/get-states
  getStates: async () => {
    const r = await api.get(`app/get-states`);
    return r.data;
  },

  //api/app/get-season-limit
  getSeasonalLimit: async () => {
    const r = await api.get(`app/get-season-limit`);
    return r.data;
  },

  //api/app/get-active-apps
  getActiveApplications: async (email: string) => {
    const r = await api.get(`app/get-user-apps?email=${email ? encodeURIComponent(email) : null}`);
    return r.data;
  },
  resetPassword: async (password: string, token: string) => {
    //api/login/change-password
    const encodePassword = password != null ? encodeURIComponent(password) : null;
    const r = await api.post(`login/change-password?password=${encodePassword}&resetToken=${token}`);
    return r.data;
  },
  forgotPassword: async (email: string) => {
    const r = await api.post(`login/forgot-password?email=${email}`);
    return r.data;
  },

  getAppPDF: async (id: number) => {
    const r = await api.get(`app/pdf?appId=${id}`);
    return r.data;
  },

  /*
   *  ADMIN
   */

  saveModifiedAplication: async (app: any): Promise<{ application: ApplicationForm; notes: Note[] }> => {
    const r = await api.post('admin/save-application', app);
    return r.data;
  },

  getAreaReps: async () => {
    //cache per browser session
    var list = storage.session.getItem('areaReps');
    if (list) {
      return JSON.parse(list);
    } else {
      const r = await api.get(`admin/area-reps`);
      storage.session.setItem('areaReps', JSON.stringify(r.data || {}));
      return r.data;
    }
  },
  addAreaRep: async (rep: any) => {
    const r = await api.post('admin/add-area-rep', rep);
    storage.session.removeItem('areaReps');
    return r.data;
  },
  deleteAreaRep: async (rep: any) => {
    const r = await api.post('admin/delete-area-rep', rep);
    storage.session.removeItem('areaReps');
    return r.data;
  },
  // saveAppAreaRep: async (rep: any) => {
  //   const r = await api.post('admin/save-app-area-rep', rep);
  //   return r.data;
  // },
  getAdminApplicationList: async (qtr: number, year: number) => {
    const r = await api.get(`admin/applications?qtr=${qtr}&year=${year}`);
    return r.data;
  },
  // getCurrentQuarter: async () => {
  //   const r = await api.get(`admin/getQtr`)
  //   return r.data
  // },
  getDMAs: async () => {
    //cache per browser session
    var list = storage.session.getItem('dmaList');
    if (list) {
      return JSON.parse(list);
    } else {
      const r = await api.get(`admin/dmas`);
      storage.session.setItem('dmaList', JSON.stringify(r.data || {}));
      return r.data;
    }
  },
  getGrantStatusList: async () => {
    //cache per browser session
    var list = storage.session.getItem('statusList');
    if (list) {
      return JSON.parse(list);
    } else {
      const r = await api.get(`admin/grant-status-list`);
      storage.session.setItem('statusList', JSON.stringify(r.data || {}));
      return r.data;
    }
  },
  // updateAppDmaCode: async (app: any) => {
  //   const r = await api.post('admin/save-dma', app);
  //   storage.session.removeItem('dmaList');
  //   return r.data;
  // },

  // updateAppContactForm: async (app: any) => {
  //   const r = await api.post('admin/save-contact-form', app);
  //   return r.data;
  // },

  adminExcelDownload: async (year: number, qtr: number) => {
    const r = await api.get(`admin/get-excel-download?year=${year}&qtr=${qtr}`);
    r.data = r.data.map((item: ApplicationForm) => {
      if (item.updatedOn) {
        item.updatedOn = Methods.convertUtcDateToLocal(item.updatedOn);
      }
      return item;
    });
    return r.data;
  },

  getAdminApplication: async (appId: number, userId: number): Promise<MainApplication> => {
    const r = await api.get<MainApplication>(`admin/get-application?appId=${appId}&userId=${userId}`);
    return r.data;
  },

  saveAppNotes: async (app: any) => {
    const r = await api.post('admin/save-notes', app);
    return r.data;
  },

  saveAppStatus: async (app: any) => {
    const r = await api.post('admin/save-app-status', app);
    return r.data;
  },

  // saveAppAdminUser: async (app: any) => {
  //   const r = await api.post('admin/app-admin-user', app);
  //   return r.data;
  // },
  getAdminUserList: async () => {
    //cache per browser session
    var list = storage.session.getItem('adminUsers');
    if (list) {
      return JSON.parse(list);
    } else {
      const r = await api.get(`admin/admin-users`);
      storage.session.setItem('adminUsers', JSON.stringify(r.data || {}));
      return r.data;
    }
  },
  //download-file
  downloadFile: async (doc: any) => {
    const r = await api.get(`admin/download-file?Id=${doc.id}`);
    return r.data;
  },
  getDocumentContent: async (doc: any) => {
    const r = await api.get(`admin/document-content?Id=${doc.id}`);
    return r.data;
  },
  saveAppNote: async (note: any) => {
    const r = await api.post('admin/save-app-note', note);
    return r.data;
  },
  removeAppNote: async (note: any) => {
    const r = await api.post('admin/remove-app-note', note);
    return r.data;
  },
  removeDocument: async (doc: any) => {
    const r = await api.post('admin/remove-document', doc);
    return r.data;
  },
  getSchedule: async () => {
    const r = await api.get(`admin/get-schedule`);
    return r.data;
  },
  updateSchedule: async (schedule: any) => {
    const r = await api.post(`admin/update-schedule`, schedule);
    return r.data;
  },
};
