 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import router from "@/router";

  extend('required', required);
  
  @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  })
  export default class PrequalifyForm extends Vue {

      private question1:  string = "";
      private question2:  string = "";
      private question3:  string = "";
      private question4:  string = "";

      private prequalifyFailed:  boolean = false;
     
      private questionError : string = "";
       private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
      };

      private validationMsg : string = "Application site is Closed.";
      private openDialog : boolean = false;  
      private dialogTitle : string = '';


      private async created() {
          //page loaded, do stuff here          
          this.appLimit = await api.getSeasonalLimit();   
          if (this.appLimit.seasonOpen == false || this.appLimit.limitCapMet == true ) {
              this.openDialog = true;  
          }         
      }    

      private async NextClicked() {
        try {            
          var valid = await (this.$refs.prequalifyForm as Vue & { validate: () => boolean }).validate()           
          if (!valid) {
            return;
          }    
          if (this.question1 === "true" && this.question2 === "true" && this.question3 === "false" && this.question4 === "false"){
              //Redirect to another page                 
               router.push({path: "/welcome" }).catch(e => {});   
          }
          else {            
             this.prequalifyFailed = true;
          }

        } catch (e) {
           this.questionError = "Unexpected Error Occured while logging in, please contact support.";
        } 
      }

      private async CancelClicked() {
           //redirect back to dashboard
          this.prequalifyFailed = false; 
          this.question1 = "";
          this.question2 = "";
          this.question3 = "";
          this.question4 = "";
      }
     

  }

  
