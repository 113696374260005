 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import storage from '@/plugins/storage'
  import router from "@/router";
  import { EventBus } from '@/plugins/eventbus';
  import store from "@/store";
  import moment from 'moment';
  import { Methods } from '../utils/methods'

  extend('required', required);
  extend('email', email);

  const $ = require('jquery');

  @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  })
  export default class LoginForm extends Vue {

      private userEmail: string = '';
      private userPassword: string = '';      
      private loginError: string = ''; 
      private loginResult: any = {};
      private errors: any = [];
      private user: any = {};
      private convertDate: Date = new Date();
      private moment = moment;

       private appLimit: any  = {
              seasonOpen : false,
              limitCapMet : false,
              appLimitNum : '600',
              seasonalMessage : ''
            };

      private schedule : any = {
        openDate : '',
        closedDate : '',
        applicationLimit : 25,
        currentAppplications : 18,
        seasonalMessage : "",
        deferApps : false,
        seasonOpen: false,
        limitMet: false
      }  
        

      private async created() {
         //page loaded, do stuff here     
         this.user = JSON.parse(storage.local.getItem('user') || '{}');  
          this.appLimit = await api.getSeasonalLimit();  

           this.schedule = await api.getSchedule();

      }    

      private async LoginClicked() {
        try {            
          var valid = await (this.$refs.loginForm as Vue & { validate: () => boolean }).validate() 
          if (!valid) {
            return;
          }

          if (this.appLimit.seasonOpen == false || this.appLimit.limitCapMet == true ){

               if (this.user.role === 'GENERAL' ) {
                 return this.loginError = "Current Season Closed, Must Be Aministrator to Access.";
            }
          }
       
          const Response = await api.login(this.userEmail, this.userPassword);          
          if (Response.isLoginSuccessful){  
              //save user to local storage
              let sessionUser = { email: Response.email
                                , title: Response.title
                                , environ: process.env.VUE_APP_ENVIRON
                                , firstName: Response.firstName
                                , lastName: Response.lastName
                                , id: Response.id
                                , role:  Response.role
                                , expires: Response.expires
                                };
              storage.local.setItem("user", JSON.stringify(sessionUser|| {}));

              store.dispatch('auth/setToken', Response.token);
              store.dispatch('auth/setRefreshToken', Response.refreshToken);                        
              
              EventBus.$emit('user-logged-in', sessionUser.id );
             
              if(sessionUser.role.includes('ADMIN')) {
                 router.push({path: "/admin" }).catch(e => {});  
              }
              else {
                  var apps = await api.getActiveApplications(sessionUser.email);

                  if(apps.length > 0) {
                    if (Methods.isSubmittedInExistingSession(apps[0].createDt,this.schedule.openDate)) {
                      router.push({path: "/my-apps" }).catch(e => {}); 
                  }
                  else {
                    router.push({name: ( process.env.VUE_APP_ENVIRON == 'CA' ? 'ApplicationStartCA' : 'ApplicationStart'),  params: { id: 'new' } }).catch(e => {}); 
                  } 
                  }                                   
                  else {
                     router.push({name: ( process.env.VUE_APP_ENVIRON == 'CA' ? 'ApplicationStartCA' : 'ApplicationStart'),  params: { id: 'new' } }).catch(e => {});   
                  }
              }                                                          
          }
          else {
             this.loginError = Response.errorMessage;             
          }

        } catch (e) {
           this.loginError = "Unexpected Error Occured while logging in. Please provide login, password and organization name while contacting support.";
        } 
      }

      private async CancelClicked() {
        try {
           //redirect back to dashboard          
           router.push({path: "/" }).catch(e => {});   
        } catch (e) {
            this.loginError = "";
        } finally {
            this.loginError = "";
        }
      }

     

  }

  
