import moment from 'moment';

export class Methods {
  private readonly moment = moment;
  static isSubmittedInExistingSession(appSubmittedDate: string, sessionOpenDate: string): boolean {
    if (moment(moment(appSubmittedDate).format('l')).isSameOrAfter(moment(moment(sessionOpenDate).format('l')))) {
      return true;
    }
    return false;
  }

  static convertUtcDateToLocal(utcDate: string): string {
    if (!utcDate)
      return '';
    return moment.utc(utcDate).local().format('lll');
  }
}
