import _Vue from "vue"; // <-- notice the changed import
import Sidebar from "./SideBar.vue";
import SidebarLink from "./SidebarLink.vue";

//import mixins from 'vue-typed-mixins';

const SidebarStore = {
  showSidebar: false,
  displaySidebar: function(value: boolean) {
    this.showSidebar = value;
  }
};

export default function SidebarPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.mixin({
    data() {
      return {
        sidebarStore: SidebarStore
      };
    }
  });

  Object.defineProperty(Vue.prototype, "$sidebar", {
    get() {
      return this.$root.sidebarStore;
    }
  });

  // do stuff with options
  Vue.component("side-bar", Sidebar);
  Vue.component("sidebar-link", SidebarLink);
}

//export default SidebarPlugin;

/*
const SidebarPlugin = Vue.extend({
  data() {
    return {
      sidebarStore: SidebarStore
    }
  } 
});

{
  install(Vue: Vue) {
    Vue.mixin({
      data() {
        return {
          sidebarStore: SidebarStore
        };
      }
    });

    Object.defineProperty(Vue.prototype, "$sidebar", {
      get() {
        return this.$root.sidebarStore;
      }
    });
    Vue.component("side-bar", Sidebar);
    Vue.component("sidebar-link", SidebarLink);
  }
};
*/
