
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DropDown extends Vue {
  //name: "drop-down",
  @Prop() private title!: String;
  @Prop() private icon!: String;
  @Prop() private isOpen!: Boolean;

  private toggleDropDown() {
    this.isOpen = !this.isOpen;
  }

  private closeDropDown() {
    this.isOpen = false;
  }

  data() {
    return {
      isOpen: false
    };
  }

  /*
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    }
  }
  */
}
