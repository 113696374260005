 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'  
  import SmartTable from 'vuejs-smart-table';
  import router from "@/router";

  const $ = require('jquery');

 @Component({
  components: {
  
  },
  })
  export default class appForm extends Vue {
      private user: any = {};
      private statusList: any = []; 
      private headerText : string = "Area Representatives";     

      private currentPage: number = 1;
      private totalPages: number = 10;
      private pageSize: number = 5;
      private filters: any= { 
          name: { value: '', keys: ['firstName', 'lastName'] }        
      };
      
      private areaRep : any = {
        firstName: "",
        lastName: "",
        id: 0
      };  

      private areaReps : any = [];           
 
      private async created() {
           $('#app-loading').fadeIn(1000);   
           //page loaded, do stuff here          
           this.user = JSON.parse(storage.local.getItem("user") || '{}'); 
         
           this.areaReps = await api.getAreaReps();
          $('#app-loading').fadeOut(1000);   
      }  

      private async AddClicked() { 
        this.showhideAlert(true, 'Saving...', true, 'success'); 
        try {
         
           this.areaReps = await api.addAreaRep(this.areaRep);

        }
        catch (e) {
           //this.formError = "Unexpected Error Occured saving form, please contact support.";
           //display toaster
        }   
      }  

      private async DeleteClicked(row : any) {  
         this.showhideAlert(true, 'Saving...', true, 'success');
         try {         
          this.areaReps = await api.deleteAreaRep(row);
        }
        catch (e) {
           //this.formError = "Unexpected Error Occured saving form, please contact support.";
           //display toaster
        }   

      }

     private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any)
     {
         $('#app-alert').removeClass('d-none');
         if (status == 'error') {
             $('#app-alert').addClass('alert-danger');
             $('#app-alert').removeClass('alert-success');
         }
         else {
             $('#app-alert').addClass('alert-success'); 
             $('#app-alert').removeClass('alert-danger'); 
         }
         if (showAlert == true) {
            if (showLoading == false) { 
                $('#app-alert-spinner').addClass('d-none'); 
            }
            else {
                $('#app-alert-spinner').removeClass('d-none'); 
            }
            $('#app-alert-msg').html(msgText);
            $('#app-alert').fadeIn(1000);
            setTimeout(function () {
                  $('#app-alert').fadeOut(2000);
             }, 3000);       
         }
         else {
             $('#app-alert').fadeOut(2000);
         }
      }    
           
      

      
  }

  
