 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import storage from '@/plugins/storage'
  import router from "@/router";
  import { EventBus } from '@/plugins/eventbus';
   import store from "@/store";

  extend('required', required);
  extend('email', email);

/*
  extend('confirmpassword', {
    params: ['target'],
    validate: (value, { target }) => {
     return value === target;
    },    
    message: 'Password confirmation does not match'
  });
  */
  
  const $ = require('jquery');

 

  @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  })
  export default class userForm extends Vue {

      private userEmail: string = '';
      private userPassword: string = ''; 
      private userPasswordConfirm: string = '';   
      private firstName: string = ''; 
      private lastName: string = '';   
      private title : string = '';
      private target : any =  { target: null };
        
      private loginError: string = ''; 
      private loginResult: any = {};
      private errors: any = [];   
      
     private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
  };

      private async created() {
         //page loaded, do stuff here     
      }    

      private async SubmitClicked() {
        this.appLimit = await api.getSeasonalLimit(); 

         if(this.appLimit.limitCapMet == true){
           $('#form-confirm-dialog').modal('show');
           return;
         }

        try {            
          var valid = await (this.$refs.userForm as Vue & { validate: () => boolean }).validate() 
          if (!valid || this.checkConfirmPassword() == false ) {
            return;
          }
          const user = await api.addUser(this.firstName,this.lastName, this.title, this.userEmail, this.userPassword );          
          if (user.id > 0){      
             //save user to local storage
              let sessionUser = { email: user.email, title: user.title, firstName: user.firstName, lastName: user.lastName, id: user.id,  role: 'GENERAL'  };
              storage.local.setItem("user", JSON.stringify(sessionUser|| {}));
              
              store.dispatch('auth/setToken', user.token);
              store.dispatch('auth/setRefreshToken', user.refreshToken);  
              EventBus.$emit('user-logged-in', sessionUser.id );      

              router.push({name: ( process.env.VUE_APP_ENVIRON == 'CA' ? 'ApplicationStartCA' : 'ApplicationStart'),  params: { id: 'new' } }).catch(e => {});  
          }
          else {
             this.loginError = user.errorMessage;             
          }

        } catch (e) {
           this.loginError = "Unexpected Error Occured while logging in, please contact support.";
        } 
      }

    private checkConfirmPassword() {
       if (this.userPasswordConfirm != this.userPassword) {
          $('#userPasswordConfirm').addClass('is-invalid');
          $('#confirmPasswordFeedback').html('Passwords do not match.');
          return false;
       }
       else {
          $('#userPasswordConfirm').removeClass('is-invalid');
          $('#confirmPasswordFeedback').html('');
          return true;
       }
    }
     

  }

  
