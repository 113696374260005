import md5 from "md5";
// import * as crypto from 'crypto'
// import Cookies from 'universal-cookie'

const path: string = "/";

/*
function universalCookie(req): Cookies {
  return req && req.headers && req.headers.cookie
    ? new Cookies(req.headers.cookie)
    : new Cookies()
}
*/

function hash(value: string) {
  /*
  return crypto
    .createHash('md5')
    .update(`${process.env.APP_NAME}/${process.env.NODE_ENV}/${value}`)
    .digest('hex')
    */
  return md5(`${process.env.APP_NAME}/${process.env.NODE_ENV}/${value}`);
}

export default {
  /*
  cookies: {
    setItem(key: string, value: string, expiresInMilliseconds: number = 0, req?: any) {
      const cookies = universalCookie(req)
      const expires = expiresInMilliseconds > 0
        ? new Date(new Date().setTime(new Date().getTime() + expiresInMilliseconds))
        : undefined
      cookies.set(hash(key), value, { expires, path })
    },
    getItem(key: string, req?: any) {
      const cookies = universalCookie(req)
      return cookies.get(hash(key))
    },
    clear(key: string, req?: any) {
      const cookies = universalCookie(req)
      cookies.remove(hash(key), { path })
    },
  },
  */
  local: {
    clear() {
      localStorage.clear();
    },
    removeItem(key: string) {
      localStorage.removeItem(key);
    },
    getItem(key: string): string | null {
      return localStorage.getItem(key);
    },
    setItem(key: string, value: string) {
      localStorage.setItem(key, value);
    }
  },
  session: {
    clear() {
      sessionStorage.clear();
    },
    getItem(key: string): string | null {
      return sessionStorage.getItem(key);
    },
    removeItem(key: string) {
      sessionStorage.removeItem(key);
    },
    setItem(key: string, value: string) {
      sessionStorage.setItem(key, value);
    }
  }
};
