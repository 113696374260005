
import { Component, Prop, Vue } from "vue-property-decorator"; 
import ContentFooter from "./ContentFooter.vue";
import AdminContent from "./Content.vue";
import AdminSidebar from "@/views/Layout/AdminSidebar.vue";
import SideBar from "@/Components/SidebarPlugin.vue";
import toaster from '@/components/Toasters/Toasters.vue';
const $ = require('jquery');

@Component({
  components: {
    AdminSidebar,
    AdminContent,
    ContentFooter,
    toaster
  }
})

export default class AdminLayout extends Vue {
  private async created() {
      //page loaded, do stuff here 
     
      $('.wrapper').click(function() {
         $('#admin-sidebar').removeClass('close-layer');
         $('#admin-sidebar').removeClass('visible');
         alert()
      });
     
  }  

  private openSideNavClicked() {
     $('#admin-sidebar').addClass('close-layer');
     $('#admin-sidebar').addClass('visible');
  }

}
