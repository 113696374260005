export type GrantStatus = 'Incomplete' | 'Complete' | 'Approved' | 'Denied' | 'Alternate' | 'Recommended' | 'Not Recommended';

export interface MainApplication {
  application: ApplicationForm;
  documents: UploadedDocument[];
  notes: Note[];
}

export interface ApplicationForm {
  areaRep: string;
  areaRepId: number;
  adminUserId: number;
  assignedAdminName: string;
  donationAmtDisplay: string;
  grantStatus: GrantStatus;
  donationAmt: string;
  dma: string;
  dmaCode: number;
  franchisee: string;
  year: number;
  qtr: number;
  contactForm: string;
  arSupport: string;
  equipment: string;
  notes: string;
  updates: string;
  appId: number;
  firstName: string;
  lastName: string;
  contactPerson: string;
  contactMobilePhone: string;
  contactEmail: string;
  title: string;
  organization: string;
  orgRegNumber: string;
  taxId: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mailingSameShipping: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  orgPhone: string;
  orgPhoneExt: string;
  altContactName: string;
  altContactPhone: string;
  altContactPhoneExt: string;
  altContactEmail: string;
  commServed: string;
  commPopulation: string;
  runsPerYear: string;
  receivedFundingCA: string;
  localApproval: string;
  localApprovalDisplay: string;
  isApplicantComplete: string;
  showShippingAddress: string;
  grantRequestType: string;
  grantRequestTypeDisplay: string;
  isRequestComplete: boolean;
  requestInformation: string;
  vendorCompanyName: string;
  salesRepName: string;
  salesRepEmail: string;
  requestFundingAmt: string;
  requestDetails: string;
  childrenSeniorImpactDetails: string;
  acceptFundingVarianceInd: number;
  acceptFundingVarianceDisplay: string;
  previousFundingInd: string;
  previousFundingIndDisplay: string;
  nbrOfTimesPrevSubmitted: number;
  nbrofChildrenImpacted: number;
  nbrofSeniorsImpacted: number;
  nbrOfScholarships: number;
  scholarshipDesc: string;
  appliedInThePast: string;
  appliedInThePastDisplay: string;
  salesTax: string;
  shippingAmount: string;
  synopsis: string;
  nearestFranchiseeName: string;
  //nearestRestaurantAddress: string;
  nearestRestaurantStreet:string;
  nearestRestaurantSuite:string;
  nearestRestaurantCity:string;
  nearestRestaurantState:string;
  nearestRestaurantZip:string

  isFranchiseComplete: boolean;
  distanceToRestaurant: number;
  howDidTheyHear: string;
  acceptanceInitials: string;
  pIOName: string;
  pIOEmail: string;
  pIOPhone: string;
  cityRequestedFunds: string;
  isCommunityComplete: boolean;
  equipmentImpactInstance: string;
  equipmentPositiveEffects: string;
  communityPositiveEffects: string;
  applicantOrganizationName: string;
  applicantDate: string;
  applicantFullName: string;
  acceptTerms: string;
  isPrintVideoAccepted: boolean;
  isDocumentComplete: boolean;
  statusId: number;
  createDt: string;
  activeDt: string;
  isComplete: boolean;
  isSubmitted: boolean;
  applicationStatus: string;
  userId: number;
  applicantCellPhoneNumber: string;
  createdBy: number;
  createdOn: string;
  updatedBy: number;
  updatedOn: string;
  deletedBy: string;
  deletedOn: string;
}

export class Note {
  id: number;
  content: string;
  createDt: string;
  userId: number;
  appId: number;
  createdBy: string;
  constructor(id: number, content: string, userId: number, appId: number, createdBy: string) {
    const date = new Date();
    this.id = id;
    this.content = content;

    this.createDt = date.toISOString();
    this.userId = userId;
    this.appId = appId;
    this.createdBy = createdBy;
  }
}

export interface UploadedDocument {
  id: number;
  documentType: number;
  documentTypeDesc: string;
  fileName: string;
  content: string;
  contentType: string;
  orgin: string;
  binaryData: string;
}
