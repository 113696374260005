
import { Component, Vue } from 'vue-property-decorator'
import storage from '@/plugins/storage'
import api from '@/plugins/api'
import router from "@/router";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min_value } from 'vee-validate/dist/rules';
import { CurrencyDirective } from 'vue-currency-input'
const $ = require('jquery');

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});
extend('email', email);
extend('min_value', min_value);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    currency: CurrencyDirective
  }
})
export default class appPg2Form extends Vue {
  private user: any = {};
  private states: any = [];
  private Documents: any = {};
  private errorMsg: string = '';
  private errors: string = '';

  private contentHTML: string = "";
  private isLoading: Boolean = true;

  private app: any = {
    appId: 0,
    nearestRestaurantAddress: "",
    nearestRestaurantStreet: '',
    nearestRestaurantSuite: '',
    nearestRestaurantCity: '',
    nearestRestaurantState: '',
    nearestRestaurantZip: '',
    isFranchiseCompleted: false,
    distanceToRestaurant: "",
    previousFundingInd: null,
    howDidTheyHear: '',
    acceptanceInitials: "",
    pIOName: "",
    pIOEmail: "",
    pIOPhone: "",
    isSubmitted: false
  };


  private appLimit: any = {
    seasonOpen: false,
    limitCapMet: false,
    appLimitNum: '600',
    seasonalMessage: ''
  };

  private async created() {
    //get user           
    this.user = JSON.parse(storage.local.getItem('user') || '{}');

    //load states
    this.states = await api.getStates();
    console.log('new version', this.states);

    //Load Applicant Info            
    if (this.$route.params.id != 'new') {
      var id = parseInt(this.$route.params.id, 10);
      this.app = await api.getApplication(id, this.user.id);
      if (this.app.isSubmitted == true) {
        router.push({ name: "LoginConfirm" }).catch(e => { });
      }
    }
  }

  private async SubmitClicked() {
    this.appLimit = await api.getSeasonalLimit();

    if (this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false) {
      $('#form-limit-dialog').modal('show');
      return;
    }

    try {
      var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()
      if (!valid) {
        return;
      }

      this.app.distanceToRestaurant = parseFloat(this.app.distanceToRestaurant);
      this.app.previousFundingInd = parseInt(this.app.previousFundingInd);

      this.app.nearestRestaurantAddress = this.mergedAddress;

      const appResponse = await api.saveApplicationFranchise(this.app);
      console.log('new version subm clicked');
      if (appResponse) {
        if (appResponse.appId > 0) {
          //Need to let parent know to move to next page **Would be good to save to the parent screen (Applicaton)                      
          router.push({ name: "ApplicationRelease", params: { id: this.$route.params.id } });
        }
        else {
          $('#toast-success').removeClass('d-none').toast('show');
        }
      }
    } catch (e) {
      $('#toast-success').removeClass('d-none').toast('show');
    }
  }

  private async SaveClicked() {
    try {
      var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()
      if (!valid) {
        return;
      }

      this.app.previousFundingInd = parseInt(this.app.previousFundingInd);
      this.app.distanceToRestaurant = parseFloat(this.app.distanceToRestaurant);

      this.app.nearestRestaurantAddress = this.mergedAddress;

      const appResponse = await api.saveApplicationFranchise(this.app);
      console.log('new version save clicked');
      if (appResponse) {
        if (appResponse.appId > 0) {
          //display a successful toaster
          $('#toast-success-msg').html('Franchise Information saved successfully!');
          $('#toast-success').removeClass('d-none').toast('show');
        }
        else {
          $('#toast-success').removeClass('d-none').toast('show');
        }
      }
    } catch (e) {
      $('#toast-success').removeClass('d-none').toast('show');
    }
  }

  private get mergedAddress(): string {
    let str = '';

    str += this.app.nearestRestaurantStreet + ', ';

    if (this.app.nearestRestaurantSuite)
      str += this.app.nearestRestaurantSuite + ', ';

    str += this.app.nearestRestaurantCity + ', ';
    str += this.app.nearestRestaurantState + ', ';
    str += this.app.nearestRestaurantZip;

    return str;
  }

  private async BackClicked() {
    router.push({ name: "ApplicationCommunity", params: { id: this.$route.params.id } });
  }

  private onlyNumeric(e: any) {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '')
  }




}


