 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import router from "@/router";
  const $ = require('jquery');

  @Component
  export default class LegalForm extends Vue {
      private contentHTML: string = "";
      private isLoading: Boolean = true;
      private headerText: string = "";

      private async created() {         
         const Response = await api.getSiteContent("PRIVACY_POLICY");
         this.contentHTML = Response.content;
         this.isLoading = false;
      }  

    

  }

  
