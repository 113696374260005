<template>
  <div
    class="sidebar"   
    
  >
  
  <div class="sidebar-wrapper">

    <sidebar-link
      v-for="(link, index) in sidebarLinks"
      :key="link.name + index"
      :to="link.path"
      :link="link"
    >
    </sidebar-link>
  </div>
  
  </div>
</template>

<script>
import SidebarLink from "./SidebarLink.vue";


export default {
  components: {
    SidebarLink
  },
  name: "side-bar",
  props: {
    title: {
      type: String,
      default: "GRANT APPLICATION"
    },
    backgroundImage: {
      type: String,
      default: require("@/assets/img/sidebar-2.png")
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/logo.png")
    },
    activeColor: {
      type: String,
      default: "orange",
      validator: value => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: ``
      };
    }
  }
};
</script>
<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
</style>
