import _Vue from "vue"; // <-- notice the changed import
import { directive as vClickOutside } from "vue-clickaway";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
};
 */

export default function GlobalDirectives(
  Vue: typeof _Vue,
  options?: any
): void {
  Vue.directive("click-outside", vClickOutside);
}
