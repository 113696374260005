 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import TopNavbar from "@/views/Layout/TopNavbar.vue";
  import ContentFooter from "@/views/Layout/ContentFooter.vue";
  import router from "@/router";
  const $ = require('jquery');

  @Component({
  components: {
    TopNavbar,
    ContentFooter
  }
})
  export default class FAQForm extends Vue {
      private contentHTML: string = "";
      private isLoading: Boolean = true;
      private headerText: string = "Page Not Found";

      private async created() {         
         
      }  

      private async NextClicked() {
           
      }
     

  }

  
