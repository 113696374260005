 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import { EventBus } from '@/plugins/eventbus';
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import router from "@/router"; 
  const $ = require('jquery');

 extend('required', required);
 extend('email', email);


 @Component({
  components: {
    ValidationProvider,
    ValidationObserver  
  },
  })
  export default class appPg1Form extends Vue {
      private user: any = {};
      private states: any = [];
      private errorMsg : string = '';

      private appId : number = 0;     
      
      private showGrantType1Questions : boolean = false;
      private showGrantType2Questions : boolean = false;
    
       private app: any = {
          //applicant
          appId: '', 
          //request
          grantRequestType :  null,
          isRequestCompleted : false,
          requestInformation:  '',
          vendorCompanyName:  '',
          salesRepName:  '',
          salesRepEmail:  '',
          requestFundingAmt: '',
          confirmFundingAmt:  '',
          requestDetails: '',  
          acceptFundingVarianceInd: '',
          previousFundingInd : null,
          nbrOfTimesPrevSubmitted :  null, 
          nbrofChildrenImpacted : null,
          nbrofSeniorsImpacted : null,
          nbrOfScholarships : null,
          appliedInThePast : null      
      };    
     
      private async created() {
           //get user           
           this.user = JSON.parse(storage.local.getItem('user') || '{}');       

           //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               this.app = await api.getApplication(id, this.user.id);   
            }        

          // Listen for event and its payload.
          EventBus.$on('app-request-2-saveclicked', this.SaveClicked);
          EventBus.$on('app-request-2-submitclicked', this.SubmitClicked);
      }  

      public async SubmitClicked(id: number) {
          try {  
            var valid = await (this.$refs.app2Form as Vue & { validate: () => boolean }).validate()                        
            if (!valid) {
                return;
            }

            this.app.requestFundingAmt.replace("$", '').replace(",", '');
            const appResponse = await api.saveApplicationRequest(this.app);               
              if (appResponse) {               
                  if (appResponse.appId <= 0){  
                        $('#toast-success').removeClass('d-none').toast('show');                                    
                    } 
                    router.push({name: "ApplicationFranchiseCA", params: { id: this.$route.params.id } }); 
              }   
          } catch (e) {      
            $('#toast-success').removeClass('d-none').toast('show');               
          }  

      }

      public async SaveClicked() {
         try {          
            var valid = await (this.$refs.app2Form as Vue & { validate: () => boolean }).validate()                        
            if (!valid) { 
                return;
            } 

            this.app.requestFundingAmt.replace("$", '').replace(",", '');
            this.app.grantRequestType = 1;
           
            const appResponse = await api.saveApplicationRequest(this.app);               
            if (appResponse) {               
                 if (appResponse.appId <= 0){  
                      $('#toast-success').removeClass('d-none').toast('show');                                  
                  } 
                  //display a successful toaster
                  $('#toast-success-msg').html('Request Information saved successfully!'); 
                  $('#toast-success').removeClass('d-none').toast('show'); 
            }   
          } catch (e) {      
            $('#toast-success').removeClass('d-none').toast('show');               
          }  
      }

      private async BackClicked() {
          window.location.href = "/application/documents";   
      }
 
      private async grantType1Clicked() {
           this.showGrantType1Questions = true;
           this.showGrantType2Questions = false;
      }  

      private async grantType2Clicked() {
           this.showGrantType1Questions = false;
           this.showGrantType2Questions = true;
      }  

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }
     

  }

  
