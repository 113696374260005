 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import storage from '@/plugins/storage'
  import router from "@/router";

  extend('required', required);
  extend('email', email);

  const $ = require('jquery');

  @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  })
  export default class ResetForm extends Vue {

      private userEmail: string = '';
      private userPassword: string = '';    
      private userPasswordConfirm : string = '';  
      private loginError: string = ''; 
      private loginResult: any = {};
      private errors: any = [];
      private token : string = '';
      private showDialog: boolean = false;

      private async created() {
         //get the token parameter 
         if (this.$route.params.token != '') {
             this.token = this.$route.params.token;
         }         
      }    

      private async ChangePasswordClicked() {
        try {            
          var valid = await (this.$refs.resetForm as Vue & { validate: () => boolean }).validate() 
          if (!valid || this.checkConfirmPassword() == false ) {
            return;
          }   
          
          var response = await api.resetPassword(this.userPassword, this.token);
          if(response) {
             if (response.success) {
                 this.showDialog = true;
             }
             else {
               this.loginError = response.errorMsg + ' Reset Password was a bad link!';
             }
          }
        } catch (e) {
           this.loginError = "Unexpected Error Occured while resetting the password, please contact support.";
        } 
      }

      private async CancelClicked() {
        try {
           //redirect back to dashboard          
           router.push({path: "/login" }).catch(e => {});   
        } catch (e) {
            this.loginError = "";
        } finally {
            this.loginError = "";
        }
      }

     private checkConfirmPassword() {
       if (this.userPasswordConfirm != this.userPassword) {
          $('#userPasswordConfirm').addClass('is-invalid');
          $('#confirmPasswordFeedback').html('Passwords do not match.');
          return false;
       }
       else {
          $('#userPasswordConfirm').removeClass('is-invalid');
          $('#confirmPasswordFeedback').html('');
          return true;
       }
    }

    private resetSuccessOkClicked() {
       this.showDialog = false;
       router.push({path: "/login" }).catch(e => {});  
    }

  }

  
