
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/plugins/storage';
import api from '@/plugins/api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min_value } from 'vee-validate/dist/rules';
import router from '@/router';

const $ = require('jquery');

extend('required', required);
extend('email', email);
extend('min_value', min_value);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class appPg1Form extends Vue {
  private user: any = {};
  private states: any = [];
  private formError: string = '';

  private app: any = {
    appId: '',
    firstName: '',
    lastName: '',
    contactPerson: '', //ca
    contactMobilePhone: '', //ca
    contactEmail: '', //ca
    title: '',
    organization: '',
    orgRegNumber: '', //ca
    mailingAddress1: '',
    mailingAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingZip: '',
    mailingSameShipping: false,
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    orgPhone: '',
    orgPhoneExt: '',
    altContactName: '',
    altContactPhone: '',
    altContactPhoneExt: '',
    altContactEmail: '',
    commServed: '',
    commPopulation: '',
    runsPerYear: '',
    receivedFundingCA: '',
    localApproval: null,
    IsApplicantComplete: false,
    showShippingAddress: false,
    userId: '',
    isSubmitted: false,
  };

  private appLimit: any = {
    seasonOpen: false,
    limitCapMet: false,
    appLimitNum: '600',
    seasonalMessage: '',
  };

  private async created() {
    //load states
    this.states = await api.getStates();

    //get user
    this.user = JSON.parse(storage.local.getItem('user') || '{}');

    //Load Applicant Info
    if (this.$route.params.id != 'new') {
      var id = parseInt(this.$route.params.id, 10);
      this.app = await api.getApplication(id, this.user.id);
      if (this.app.isSubmitted == true) {
        router.push({ name: 'LoginConfirm' });
      }
    }

    this.app.userId = this.user.id;
    this.app.firstName = this.user.firstName;
    this.app.lastName = this.user.lastName;
    this.app.title = this.user.title;
  }

  private async SubmitClicked() {
    try {
      this.appLimit = await api.getSeasonalLimit();
      if (this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false) {
        $('#form-limit-dialog').modal('show');
        return;
      }

      var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate();
      if (!valid) {
        return;
      }

      //Need to let parent know to move to next page
      const appResponse = await api.saveApplicationApplicant(this.app);
      if (appResponse) {
        if (appResponse.appId > 0) {
          this.app.IsApplicantComplete = appResponse.IsApplicantComplete;
          //Need to let parent know to move to next page **Would be good to save to the parent screen (Applicaton)
          router.push({ name: 'ApplicationRequestCA', params: { id: appResponse.appId } });
        } else {
          this.formError = 'Unexpected Error Occurred, Please try again or contact support';
        }
      }
    } catch (e) {
      this.formError = 'Unexpected Error Occured saving form, please contact support.';
    }
  }

  private shippingAddressClicked() {
    this.app.showShippingAddress = this.app.mailingSameShipping;
  }

  private onlyNumeric(e: any) {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '');
  }
}
