 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import router from "@/router"; 
  const $ = require('jquery');

 extend('required', required);


 @Component({
  components: {
    ValidationProvider,
    ValidationObserver  
  },
  })
  export default class appForm extends Vue {
      private user: any = {};
      private states: any = [];
      private Documents: any = {};
      private errorMsg : string = '';
      private validationMsg : string = '';
      private downloadError : string = '';
     
      private contentHTML: string = "";
      private isLoading: Boolean = true;

      private appId : number = 0;    
      private communityPositiveEffects: string = "";
      private IsCommunityCompleted: Boolean = false ;
      private openDialog: Boolean = false ;
 
      private async created() {
           //page loaded, do stuff here          
            this.user = JSON.parse(storage.local.getItem("user") || '{}'); 
            if (this.$route.params.id != 'new') {
               this.appId = parseInt(this.$route.params.id, 10);
            }            
            this.isLoading = false;
      }  

      private async BackClicked() {
          window.location.href = "/my-apps";          
      }

      private async viewDocument() {    
        try {
          //only works in CHROME AND FIREFOX        
          var nAgt = navigator.userAgent;            
          var filecontentURL =  process.env.VUE_APP_API_URL + "/api/app/pdf?appId=" + this.appId + "&userId=" + this.user.id; //await api.getAppPDF(this.appId);  
          
          var a = window.document.createElement('a');
          a.href = filecontentURL;
          a.download = "Application.pdf";
          // Append anchor to body.
          document.body.appendChild(a);
          a.click();
          // Remove anchor from body
          document.body.removeChild(a);       
        } 
        catch(ex) {
           this.downloadError = "Unexpected Error Occured while attempting to download the application PDF, please contact support.";
        }            
      }      

  }

  
