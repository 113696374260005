
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import router from "@/router";
import storage from '@/plugins/storage'
import { EventBus } from '@/plugins/eventbus';
import store from "@/store";
import api from '@/plugins/api';
// import { Route } from "vue-router";


@Component({  
})
export default class TopNavLayout extends Vue {
     private user: any = {};
     private prequafLink : string = ( process.env.VUE_APP_ENVIRON == 'CA' ? 'prequalify-ca' : 'prequalify');

    private appLimit: any  = {
    seasonOpen : false,
    limitCapMet : false,
    appLimitNum : '600',
    seasonalMessage : ''
  };

      private async created() {
         //page loaded, do stuff here  
          this.user = JSON.parse(storage.local.getItem('user') || '{}');  

          this.appLimit = await api.getSeasonalLimit();           
      }    

      @Watch('$route', { immediate: true, deep: true })
        onUrlChange(newVal: any) {
          this.user = JSON.parse(storage.local.getItem('user') || '{}');  
      }
}

  
