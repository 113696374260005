 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import router from "@/router"; 
  //import moment from "vue-cli-plugin-moment"; 
  import { VueEditor, Quill } from "vue2-editor";
  import { Datetime } from 'vue-datetime';
  import 'vue-datetime/dist/vue-datetime.css';  

  const $ = require('jquery');

 @Component({
  components: {
   VueEditor, 
   Quill,
   datetime: Datetime
  },
  })
  export default class appForm extends Vue {
      private user: any = {};     
      private headerText : string = "Application Schedules";     
      
      private applicationsAreClosed : boolean = false;      

      private schedule : any = {
        openDate : '2020-10-01',
        closedDate : '',
        applicationLimit : 25,
        currentAppplications : 18,
        seasonalMessage : "",
        deferApps : false,
        seasonOpen: false,
        limitMet: false
      }  
      
      private customToolbar : any =  [ 
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              ['bold', 'italic', 'underline', 'strike'], 
              ['blockquote'],             
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],   
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link'], //'image', 'video'
            ];
       
 
      private async created() {
        $('#app-loading').fadeIn(1000);   
        //page loaded, do stuff here          
        this.user = JSON.parse(storage.local.getItem("user") || '{}');
        if (this.user && this.user.role) {
          if(!this.user.role.includes('SUPER')) {
            router.push({name: "Login"});
          }
        }

        //load the schedule
        this.schedule = await api.getSchedule();
         $('#app-loading').fadeOut(1000);        
        
        this.CheckIfApplicationIsClosed();
            
      }  

      private async SaveClicked() {  
          this.showhideAlert(true, 'Saving...', false, 'success');
          try {
            let Content4 = { id: 0, content: this.schedule.seasonalMessage, name : "SEASONAL_MESSAGE_HOME" , updatedBy : this.user.id };
            await api.saveSiteContent(Content4);
           
            this.schedule.applicationLimit = parseInt(this.schedule.applicationLimit);
            this.schedule.createdBy = this.user.id;
            await api.updateSchedule(this.schedule);

            this.schedule = await api.getSchedule();

            //alert
            this.showhideAlert(true, 'Application schedule saved successfully!', false, 'success'); 
            this.CheckIfApplicationIsClosed();
          }
          catch(e) {                
               this.showhideAlert(true, 'An error occurred while saving the admin user...', true, 'error');   
          }  
      }  

      private async CheckIfApplicationIsClosed() {
         var isClosed = false;
             
         if (this.schedule.seasonOpen == false  ) {
           isClosed = true;
         }
         else {
           if (this.schedule.limitMet == true) {
              isClosed = true;
           }
         }
         this.applicationsAreClosed = isClosed;
      }

      private async CancelClicked() {  
          router.push({path: '/admin'}).catch(e => {});
      }

       private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }

     private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any)
     {
         $('#app-alert').removeClass('d-none');
         if (status == 'error') {
             $('#app-alert').addClass('alert-danger');
             $('#app-alert').removeClass('alert-success');
         }
         else {
             $('#app-alert').addClass('alert-success'); 
             $('#app-alert').removeClass('alert-danger'); 
         }
         if (showAlert == true) {
            if (showLoading == false) { 
                $('#app-alert-spinner').addClass('d-none'); 
            }
            else {
                $('#app-alert-spinner').removeClass('d-none'); 
            }
            $('#app-alert-msg').html(msgText);
            $('#app-alert').fadeIn(1000);
            setTimeout(function () {
                  $('#app-alert').fadeOut(2000);
             }, 3000);       
         }
         else {
             $('#app-alert').fadeOut(2000);
         }
      }    
     
      
  }

  
