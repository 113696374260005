 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import storage from '@/plugins/storage'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import router from "../router";  

  extend('required', required);
  
  @Component({
  components: {
    ValidationProvider,
    ValidationObserver    
  },
  })
  export default class AppStartForm extends Vue {
      private appError : string = "";
      private user: any = {
        id: null
      };       
     
      private async created() {
          //try to load the app if Id Param exists
          this.user = JSON.parse(storage.local.getItem('user') || '{}');    

          if (this.user.id == null) {
            //need to check if CA  router.push({name: "PrequalificationCA"});   
            if (process.env.VUE_APP_ENVIRON == 'CA') {
              router.push({name: "PrequalificationCA"}).catch(e => {});
            }else {
               router.push({name: "Prequalification"}).catch(e => {});
            }              
          }          

      }    

     
    
  }

  
