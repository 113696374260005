 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
  import storage from '@/plugins/storage'
  import router from "@/router";

  extend('required', required);
  extend('email', email);

  const $ = require('jquery');

  @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  },
  })
  export default class LoginForm extends Vue {

      private userEmail: string = '';
      private userPassword: string = '';      
      private loginError: string = ''; 
      private loginResult: any = {};
      private errors: any = [];
      private showDialog: boolean = false;

      private async created() {
         //page loaded, do stuff here     
      }    

      private async LoginClicked() {
        try {            
          var valid = await (this.$refs.loginForm as Vue & { validate: () => boolean }).validate() 
          if (!valid) {
            return;
          }

          let email = encodeURIComponent(this.userEmail);
          
          var response = await api.forgotPassword(email);
          if(response){
             if (!response.success) {
               this.loginError = response.errorMsg;
             }
             else {
                this.showDialog = true;
             }
          }

        } catch (e) {
           this.loginError = "Unexpected Error Occured while requesting password, please contact support.";
        } 
      }

      private async CancelClicked() {
        try {
           //redirect back to dashboard          
           router.push({path: "/login" }).catch(e => {});  
        } catch (e) {
            this.loginError = "";
        } finally {
            this.loginError = "";
        }
      }

      private successOkClicked() {
       this.showDialog = false;
       router.push({path: "/login" }).catch(e => {}); 
      }
     

  }

  
