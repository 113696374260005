<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <stats-card data-background-color="orange-dark">
          <template slot="header">
            <i class="fas fa-home"></i>
            <span class="md-display-1">Getting Started</span>
          </template>
          <template slot="content">
            <p class="category" style="text-align:left;">
              <strong
                >Welcome to Firehouse Subs Public Safety Foundation's digital
                grant application. Thank you for your interest in applying
                for a grant.
              </strong>
            </p>
            <p style="text-align:left;">
              <span class="md-headline"
                ><strong>Before you Begin:</strong> Please print and read
                through our grant applicant FAQs.</span
              >
            </p>
            <br />
            <md-list>
              <md-list-item to="/prequalify">
                <md-button class="md-icon-button md-raised md-primary">
                  <span class="md-headline">1</span>
                </md-button>
                <div class="md-layout-item">
                  <span class="md-list-item-text md-title text-primary" >Start A New Application</span
                  >
                </div>
              </md-list-item>
              <md-list-item to="/login">
                <md-button class="md-icon-button md-raised md-primary">
                  <span class="md-headline">2</span>
                </md-button>
                <div class="md-layout-item">
                  <span class="md-list-item-text md-title text-primary">Log In</span
                  >
                </div>
              </md-list-item>
            </md-list>
            <br />
            <md-divider></md-divider>
            <p style="text-align:left;">
              <span class="md-body-2"><strong>Important Dates:</strong> </span>
            </p>
            <p style="text-align:left;">
              <span class="md-body-2">
                Portal reopens for 2020 Quarter 3 Grant Applications: April 1,
                2020 <br />
                Portal closes: Wednesday, May 13, 2020, at 5:00 PM ET
              </span>
            </p>
            <p style="text-align:left;">
              <span class="md-body-2">
                Portal reopens for 2020 Quarter 3 Grant Applications: April 1,
                2020 <br />
                Portal closes: Wednesday, May 13, 2020, at 5:00 PM ET
              </span>
            </p>
          </template>

          <template slot="footer">
            <div class="md-layout-item md-size-5"><b>Notes:</b></div>
            <div class="md-layout-item">
              U.S. applicants, please note, the application process is entirely
              digital. Please pay close attention to the FAQs and notes as you
              complete your online grant application. Canadian applicants, the
              Firehouse Subs Public Safety Foundation of Canada operates as a
              separate entity from the U.S. organization, Firehouse Subs Public
              Safety Foundation, Inc. with a different application process. To
              learn more and/or request a grant application, email
              CanadaFoundation@firehousesubs.com.
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StatsCard
  //ChartCard,
  //NavTabsCard,
  //NavTabsTable,
  //OrderedTable
} from "@/components";

export default {
  components: {
    StatsCard
    //ChartCard,
    //NavTabsCard,
    //NavTabsTable,
    //OrderedTable
  }
};
</script>
