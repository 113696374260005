 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import router from "@/router"; 
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email, min_value } from 'vee-validate/dist/rules';
  const $ = require('jquery');

 extend('required', required);
 extend('email', email);
  extend('min_value', min_value);

 @Component({
  components: {
    ValidationProvider,
    ValidationObserver  
  },
  })
  export default class appPg2Form extends Vue {
      private user: any = {};
      private states: any = [];
      private Documents: any = {};
      private errors : string = '';
     
      private contentHTML: string = "";
      private isLoading: Boolean = true;

       private app: any = {         
          appId: '',        
          nearestRestaurantAddress:  "",
          isFranchiseCompleted:  false,
          distanceToRestaurant: "",
          previousFundingInd : false,
          howDidTheyHear:  '',
          acceptanceInitials : "",
          communityPositiveEffects: "",
          PIOName :  "",
          PIOEmail : "",
          PIOPhone : "",
          isSubmitted: false
      };    

       private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
      };
     
      private async created() {
           //get user           
           this.user = JSON.parse(storage.local.getItem('user') || '{}');       

           //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               this.app = await api.getApplication(id, this.user.id);   
               if (this.app.isSubmitted == true) {
                  router.push({name: "LoginConfirm"});
               }    
            }   
      }  

      private async SubmitClicked() {
         try {  
           
           this.appLimit = await api.getSeasonalLimit(); 
           if(this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false){
               $('#form-limit-dialog').modal('show');
               return;
            }
           
            var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()                        
            if (!valid) {
              return;
            }
                     
            const appResponse = await api.saveApplicationFranchise(this.app);               
            if (appResponse) {               
                 if (appResponse.appId > 0){   
                      //Need to let parent know to move to next page **Would be good to save to the parent screen (Applicaton)                      
                     router.push({name: "ApplicationDocumentsCA", params: { id: this.$route.params.id } });                      
                  }
                  else {
                     $('#toast-success').removeClass('d-none').toast('show');            
                  }   
            }   
          } catch (e) {
              $('#toast-success').removeClass('d-none').toast('show');                
          }   
           
      }

      private async SaveClicked() {
           try {            
            var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()                        
            if (!valid) {
              return;
            }
                     
            const appResponse = await api.saveApplicationFranchise(this.app);               
            if (appResponse) {               
                 if (appResponse.appId > 0){   
                       //display a successful toaster
                      $('#toast-success-msg').html('Franchise Information saved successfully!'); 
                      $('#toast-success').removeClass('d-none').toast('show');      
                  }
                  else {
                     $('#toast-success').removeClass('d-none').toast('show');            
                  }   
            }   
          } catch (e) {
              $('#toast-success').removeClass('d-none').toast('show');                
          }   
      }

      private async BackClicked() {          
           router.push({name: "ApplicationRequestCA", params: { id: this.$route.params.id } })
      }

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }
     

     

  }

  
