
import { Component, Prop, Vue } from "vue-property-decorator";
import TopNavbar from "@/views/Layout/TopNavbar.vue";
import ContentFooter from "@/views/Layout/ContentFooter.vue";
import router from "@/router";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import api from '@/plugins/api';

@Component({
  components: {
    TopNavbar,
    ContentFooter,
    vueCustomScrollbar
  }
})
export default class HomeView extends Vue {
  private showSidepanel : boolean  = false;
  private appLimit: any  = {
    seasonOpen : false,
    limitCapMet : false,
    appLimitNum : '600',
    seasonalMessage : ''
  };
  private openDialog : boolean = false;
  private appClosedMessage : string = '<h3>Application Season is Closed.</h3><br>';
  private validationMsg : string = "Application is Closed.";

  private async startClicked() {
      router.push({ path: ( process.env.VUE_APP_ENVIRON == 'CA' ? 'prequalify-ca' : 'prequalify') }).catch(e => {});    
  }

   private async created() {
      this.appLimit = await api.getSeasonalLimit();   
      this.appClosedMessage = (this.appLimit.seasonOpen == false ? this.appClosedMessage + this.appLimit.seasonalMessage : '') 
    }    

}
