//import Axios from 'axios'
import storage from "@/plugins/storage";

interface IState {
  isRefreshingToken: boolean
  token: string | null
  refreshToken: string | null
}

enum session {
  token = 'auth/token',
  user = 'user',
  refreshToken = 'auth/refreshtoken'
}

const state: IState = {
  isRefreshingToken: false,
  token: (storage.local.getItem(session.token) ? storage.local.getItem(session.token) : ''),
  refreshToken: (storage.local.getItem(session.refreshToken) ? storage.local.getItem(session.refreshToken) : ''),
}

const getters = { 
  isRefreshingToken: (state: IState) => state.isRefreshingToken,
  token: (state: IState) => state.token,
  refreshToken: (state: IState) => state.refreshToken,
};

const mutations = { 
  SET_IS_REFRESHING_TOKEN(state: IState, isChecking: boolean) {
    state.isRefreshingToken = isChecking
  },
  SET_TOKEN(state: IState, data: any) {
    state.token = data
    storage.local.setItem(session.token, data)
  },
  SET_REFRESH_TOKEN(state: IState, data: any) {
    state.token = data
    storage.local.setItem(session.refreshToken, data)
  }
};

const actions = { 
  clearToken(context: any) {
    storage.local.removeItem(session.token);
  },
  setIsRefreshingToken(context: any, isChecking: boolean) {
    context.commit('SET_IS_REFRESHING_TOKEN', isChecking)
  },
  setToken(context: any, token: any) {
    context.commit('SET_TOKEN', token)
  },
  clearRefreshToken(context: any) {
    storage.local.removeItem(session.refreshToken);
  },
  setRefreshToken(context: any, token: any) {
    context.commit('SET_REFRESH_TOKEN', token)
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
  actions
};
