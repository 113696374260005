 
  import { Component, Vue } from 'vue-property-decorator'
  import { EventBus } from '@/plugins/eventbus';
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';
   import router from "@/router"; 

  import ApplicationRequest1 from "@/views/ApplicationCA/Request/ApplicationRequest1.vue";
  import ApplicationRequest2 from "@/views/ApplicationCA/Request/ApplicationRequest2.vue";

  const $ = require('jquery');

 extend('required', required);
 extend('email', email);


 @Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ApplicationRequest1,
    ApplicationRequest2  
  },
  })
  export default class appPg1Form extends Vue {
      private user: any = {};
      private states: any = [];
      private errorMsg : string = '';

      private appId : number = 0;
      private grantRequestType : number | null = null;
      private IsRequestCompleted : boolean = false;
      private showGrantType1Questions : boolean = true;
      private showGrantType2Questions : boolean = false;
      private requestInformation: string = ''; 
      private vendorCompanyName: string = ''; 
      private salesRepName: string = ''; 
      private salesRepEmail: string = ''; 
      private requestFundingAmt: string = ''; 
      private confirmFundingAmt: string = '';
      private requestDetails: string = '';       
      private previousFundingInd : number | null = null;
      private nbrOfTimesPrevSubmitted : number | null = null;
      private nbrofChildrenImpacted : number | null = null;
      private nbrofSeniorsImpacted : number | null = null;
      private nbrOfScholarships : number | null = null;
      private UserId : number = 0;

      private appLimit: any  = {
        seasonOpen : false,
        limitCapMet : false,
        appLimitNum : '600',
        seasonalMessage : ''
      };
 
     
      private async created() {
           //page loaded, do stuff here          
           this.user = JSON.parse(storage.local.getItem("user") || '{}');     
           
            //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               var app = await api.getApplication(id, this.user.id);
               if (app.isSubmitted == true) {
                  router.push({name: "LoginConfirm"});
               } 
               //check if previous screen is completed and check if isSubmited
            }
            else {
               window.location.href = "/my-apps";
            }   
      }        

       private async SubmitClicked() {
           this.appLimit = await api.getSeasonalLimit(); 
           if(this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false){
               $('#form-limit-dialog').modal('show');
               return;
            }

          var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()                        
          if (!valid) {
              return;
          }

          if(this.showGrantType1Questions == true) {
              EventBus.$emit('app-request-1-submitclicked', this.appId);
          } 
          
          if(this.showGrantType2Questions == true) {
              EventBus.$emit('app-request-2-submitclicked', this.appId);
          } 
          
          this.IsRequestCompleted = true;

      }

      private async SaveClicked() {
          var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()                        
          if (!valid) {
              return;
          }          
         
          if(this.showGrantType1Questions == true) {
              EventBus.$emit('app-request-1-saveclicked', this.appId);
           } 
          
          if(this.showGrantType2Questions == true) {
              EventBus.$emit('app-request-2-saveclicked', this.appId);
          } 
          
          
          //this.$refs.requestForm1.appReq1Form.SaveClicked();
          //create the document model / application Id

          //submit

          //this.$emit('submitted', 'ApplicantFormCompleted');
          //this.IsRequestCompleted = true;
      }

      private async BackClicked() {         
          router.push({name: "ApplicationStartCA", params: { id: this.$route.params.id } })
      }
     

      private async grantType1Clicked() {
           this.showGrantType1Questions = true;
           this.showGrantType2Questions = false;
      }  

      private async grantType2Clicked() {
           this.showGrantType1Questions = false;
           this.showGrantType2Questions = true;
      }  

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }
     

  }

  
