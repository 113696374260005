 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'  
  import storage from '@/plugins/storage'
  const $ = require('jquery');
  const img =  require("@/assets/img/faces/marc.jpg");

  @Component
  export default class userprofForm extends Vue {

      private user: any = {};    
       
     
      private async created() {
         //page loaded, do stuff here    
         this.user = JSON.parse(storage.local.getItem("user") || '{}');       
      }    

      private async logout() {
           storage.local.clear();           
           window.location.href = "/"
      }
  }

  
