
import { Component, Prop, Vue } from "vue-property-decorator";
import storage from '@/plugins/storage'
import router from "../../router";
const $ = require('jquery');

@Component({  
      router
})
export default class AdminSideNavLayout extends Vue {
      private legalLink : string = "Edit Policy, Legal Disclosure & FAQ";
      private user: any = {};    


      private async created() {
            this.user = JSON.parse(storage.local.getItem("user") || '{}');
          
      }    

      private closeNavClicked() {
            $('#admin-sidebar').removeClass('close-layer');
            $('#admin-sidebar').removeClass('visible');
      }
      
      private linkClicked() {
            $('#admin-sidebar').removeClass('close-layer');
            $('#admin-sidebar').removeClass('visible');
      }

      private async logout() {
           storage.local.clear();         
           router.push({path: '/'});
      }

  
}
