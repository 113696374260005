 
  import { Component, Vue } from 'vue-property-decorator'
  import api from '@/plugins/api'
  import router from "@/router";
  const $ = require('jquery');

  @Component
  export default class FAQForm extends Vue {
      private contentHTML: string = "";
      private isLoading: Boolean = true;
      private headerText: string = "Frequently Asked Questions & Tips";

      private async created() {         
         const Response = await api.getSiteContent("FAQ");
         this.contentHTML = Response.content;
         this.isLoading = false;
      }  

      private async NextClicked() {
           //redirect back to dashboard
           window.location.href = "/guidelines"      
      }
     

  }

  
