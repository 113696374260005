 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import router from "@/router"; 
 
  //import moment from "vue-cli-plugin-moment"; 
  const $ = require('jquery');

 extend('required',  {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
 });

 @Component({
  components: {
    ValidationProvider,
    ValidationObserver,      
  },
  })
  export default class appForm extends Vue {
      private user: any = {};
      private states: any = [];
      private Documents: any = {};
      private errorMsg : string = '';
    
      private appId : number = 0;    
      private applicantOrganizationName: string = "";
      private applicantDate: string = "";
      private applicantFullName : string = "";
      private acceptTerms : number = 0;
      private IsPrintVideoAccepted: Boolean = false ;
      private isSubmitted : Boolean = false ;
      private validationMsg : string = '';
      private openDialog: Boolean = false ;
      private dialogTitle : string = "";
      private isAppCompleted: Boolean = false;
      private appRedirect : string = "";
      private openValidationDialog : Boolean = false;

      private app: any = {         
          appId: 0,   
          applicantOrganizationName: '',
          applicantDate : '',          
          applicantFullName: '',
          acceptTerms: false,
          isPrintVideoAccepted: false,
          isSubmitted : false
      };    

  private appLimit: any  = {
              seasonOpen : false,
              limitCapMet : false,
              appLimitNum : '600',
              seasonalMessage : ''
  };

      private seasonal : any = {
      };
 
      private async created() {
          //get user           
           this.user = JSON.parse(storage.local.getItem('user') || '{}');       

           //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               this.app = await api.getApplication(id, this.user.id);   
               if (this.app.isSubmitted == true) {
                   this.dialogTitle = "Your application has already been submitted!";
                   this.validationMsg = ("<p>Our distributions are done on a quarterly basis and approved or denied by the Foundation's Board of Directors. All applicants will be considered and notified after distributions are made whether support will be granted.</p>");
                   this.openDialog = true;   
               }               
           } 

          var dt = new Date()
          if (this.app.applicantDate == null) {              
              this.app.applicantDate =  (dt.getMonth() + 1) + '/' + dt.getDate() +  '/' + dt.getFullYear();
          }
          else {
              dt = new Date(this.app.applicantDate);
              this.app.applicantDate =  (dt.getMonth() + 1) + '/' + dt.getDate() +  '/' + dt.getFullYear();
          }
            
      }  

      private async SubmitClicked() {        
         var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()          
         if (!valid) { 
            return;
         }

        this.appLimit = await api.getSeasonalLimit(); 
        if(this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false){
           $('#form-limit-dialog').modal('show');
           return;
         }

         if (this.app.acceptTerms == false) {
           this.dialogTitle = "Validation Error!";
           this.validationMsg = "Accepting the Terms of the Agreement is required.";
           this.openValidationDialog = true;
           return;
         } 

          //Check if the application is completed
          // this.isAppCompleted = (this.app.isApplicantComplete && this.app.isDocumentComplete && this.app.isRequestComplete && this.app.isCommunityComplete && this.app.isFranchiseComplete  ?  true : false    )
          // if(this.isAppCompleted == false) {
          //     this.dialogTitle = "Application is not Completed!";
          //     this.validationMsg = "Please complete the following sections before submitting: <br> <ul>";
          //     this.validationMsg += (this.app.isApplicantComplete == false ? '<li>Applicant and Department </li>' : "");
          //     this.validationMsg += (this.app.isDocumentComplete == false ? '<li>Required Documents</li>' : "");
          //     this.validationMsg += (this.app.isRequestComplete == false ? '<li>Request Information</li>' : "");
          //     this.validationMsg += (this.app.isCommunityComplete == false ? '<li>Community Information</li>' : "");
          //     this.validationMsg += (this.app.isFranchiseComplete == false ? '<li>Franchise Information</li>' : "");        
          //     this.validationMsg += "</ul>";      
          //     this.openDialog = true;  
          //     return;
          // } 

          //TODO: Might need one more check here - IF USER already has a submitted application for the season - DONT ALLOW SUBMIT
          //if rule is not to allow multiple submitted apps within a season
         
          //Check if app season or limit is met before submitting
          $('#form-confirm-dialog').modal('show');         
      }
    
      private async BackClicked() {        
           router.push({name: "ApplicationFranchise", params: { id: this.$route.params.id } });          
      }

      private async dialogOkClicked() {
          if(this.isAppCompleted == false && this.app.isSubmitted == false) { 
              this.appRedirect = (this.app.isFranchiseComplete == false ? 'ApplicationFranchise' : this.appRedirect);
              this.appRedirect = (this.app.isCommunityComplete == false ? 'ApplicationCommunity' : this.appRedirect);
              this.appRedirect = (this.app.isRequestComplete == false ? 'ApplicationRequest' : this.appRedirect);
              this.appRedirect = (this.app.isDocumentComplete == false ? 'ApplicationDocuments' : this.appRedirect);        
              this.appRedirect = (this.app.isApplicantComplete == false ? 'ApplicationStart' : this.appRedirect);    
              router.push({name: this.appRedirect, params: { id: this.$route.params.id } });               
          } 
          else {
            router.push({name: "LoginConfirm" });    
          }
      }

      private async confirmNextClicked() {
           this.app.isPrintVideoAccepted = true;
           this.app.isSubmitted = true;
           this.app.userId = this.user.id;

           try {            
            var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate()                        
            if (!valid) {
              return;
            }

            if(this.app.isDocumentComplete == false || this.app.isCommunityComplete == false || this.app.isRequestComplete == false ){
                   $('#form-notComplete-dialog').modal('show');
           return;
            } else {
              const appResponse = await api.saveApplicationPrintRelease(this.app);               
              if (appResponse) {               
                 if (appResponse.appId > 0){ 
                      router.push({name: "ApplicationConfirm", params: { id: appResponse.appId } });                       
                  }
                  else {
                     $('#toast-success').removeClass('d-none').toast('show');            
                  }   
              } 
            }                     
          } catch (e) {
              $('#toast-success').removeClass('d-none').toast('show');                
          }             
      }
     

  }

  
