 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import router from "../../router"; 
 
  import { VueEditor, Quill } from "vue2-editor";

  const $ = require('jquery');

 @Component({
  components: {
   VueEditor, 
   Quill
  },
  })
  export default class appForm extends Vue {
      private user: any = {};
      private statusList: any = []; 
      private headerText : string = "Policy Legal Disclosure & FAQ";     
      
      private privacyData : string = "";
      private faqData : string = "";
      private legalData : string = "";

      private editor : any = {};
      private customToolbar : any =  [ 
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              ['bold', 'italic', 'underline', 'strike'], 
              ['blockquote'],             
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],   
             
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link'], //'image', 'video'
            ];
       
 
      private async created() {
           $('#app-loading').fadeIn(1000); 
           //page loaded, do stuff here          
           this.user = JSON.parse(storage.local.getItem("user") || '{}'); 
         
           const Response1 = await api.getSiteContent("PRIVACY_POLICY");
           this.privacyData = Response1.content;

           const Response2 = await api.getSiteContent("FAQ");
           this.faqData = Response2.content;

           const Response3 = await api.getSiteContent("LEGAL_DISCLOSURE");
           this.legalData = Response3.content;
           $('#app-loading').fadeOut(1000); 
      }  

      private async SaveClicked() {  
          this.showhideAlert(true, 'Saving...', false, 'success');
          let privacyContent = { id: 0, content: this.privacyData, name : "PRIVACY_POLICY", updatedBy : this.user.id };
          await api.saveSiteContent(privacyContent);

          let faqContent = { id: 0, content: this.faqData, name : "FAQ" , updatedBy : this.user.id };
          await api.saveSiteContent(faqContent);

           let legalContent = { id: 0, content: this.legalData, name : "LEGAL_DISCLOSURE" , updatedBy : this.user.id };
          await api.saveSiteContent(legalContent);
          this.showhideAlert(true, 'Application content saved successfully!', false, 'success');
      }  

       private async CancelClicked() {  
          router.push({path: '/admin'}).catch(e => {});
      }

     private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any)
     {
         $('#app-alert').removeClass('d-none');
         if (status == 'error') {
             $('#app-alert').addClass('alert-danger');
             $('#app-alert').removeClass('alert-success');
         }
         else {
             $('#app-alert').addClass('alert-success'); 
             $('#app-alert').removeClass('alert-danger'); 
         }
         if (showAlert == true) {
            if (showLoading == false) { 
                $('#app-alert-spinner').addClass('d-none'); 
            }
            else {
                $('#app-alert-spinner').removeClass('d-none'); 
            }
            $('#app-alert-msg').html(msgText);
            $('#app-alert').fadeIn(1000);
            setTimeout(function () {
                  $('#app-alert').fadeOut(2000);
             }, 3000);       
         }
         else {
             $('#app-alert').fadeOut(2000);
         }
      }    
      
  }

  
