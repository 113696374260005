 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import api from '@/plugins/api'
  import router from "../../router";  
  import { VueEditor, Quill } from "vue2-editor";

  const $ = require('jquery');

 @Component({
  components: {
   VueEditor, 
   Quill
  },
  })
  export default class appForm extends Vue {
      private user: any = {};
      private statusList: any = []; 
      private headerText : string = "Manage Application Content";     
      
      private welcomeData : string = "";
      private guideData : string = "";
      private docsData : string = "";
      private limitData : string = "";

      private editor : any = {};
      private customToolbar : any =  [ 
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'size': [] }],
              ['bold', 'italic', 'underline', 'strike'], 
              ['blockquote'],             
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],   
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link'], //'image', 'video'
            ];
       
 
      private async created() {
           //page loaded, do stuff here    
           $('#app-loading').fadeIn(1000);        
           this.user = JSON.parse(storage.local.getItem("user") || '{}'); 
         
           const Response1 = await api.getSiteContent("APPLICATION_START_WELCOME");
           this.welcomeData = Response1.content;

           const Response2 = await api.getSiteContent("APPLICATION_START_GUIDELINES");
           this.guideData = Response2.content;

           const Response3 = await api.getSiteContent("APPLICATON_DOCUMENTS");
           this.docsData = Response3.content;

           const Response4 = await api.getSiteContent("APPLICATION_LIMIT_EXCEEDED");
           this.limitData = Response4.content;
           $('#app-loading').fadeOut(1000);   
      }  

      private async SaveClicked() {  
          this.showhideAlert(true, 'Saving...', true, 'success');
          let Content1 = { id: 0, content: this.welcomeData, name : "APPLICATION_START_WELCOME", updatedBy : this.user.id };
          await api.saveSiteContent(Content1);

          let Content2 = { id: 0, content: this.guideData, name : "APPLICATION_START_GUIDELINES", updatedBy : this.user.id };
          await api.saveSiteContent(Content2);

           let Content3 = { id: 0, content: this.docsData, name : "APPLICATON_DOCUMENTS", updatedBy : this.user.id };
          await api.saveSiteContent(Content3);

          let Content4 = { id: 0, content: this.limitData, name : "APPLICATION_LIMIT_EXCEEDED", updatedBy : this.user.id };
          await api.saveSiteContent(Content4);
          this.showhideAlert(true, 'Application content saved successfully!', false, 'success');  
      }  

      private async CancelClicked() {  
         router.push({path: '/admin'});
      }
          
     private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any)
     {
         $('#app-alert').removeClass('d-none');
         if (status == 'error') {
             $('#app-alert').addClass('alert-danger');
             $('#app-alert').removeClass('alert-success');
         }
         else {
             $('#app-alert').addClass('alert-success'); 
             $('#app-alert').removeClass('alert-danger'); 
         }
         if (showAlert == true) {
            if (showLoading == false) { 
                $('#app-alert-spinner').addClass('d-none'); 
            }
            else {
                $('#app-alert-spinner').removeClass('d-none'); 
            }
            $('#app-alert-msg').html(msgText);
            $('#app-alert').fadeIn(1000);
            setTimeout(function () {
                  $('#app-alert').fadeOut(2000);
             }, 3000);       
         }
         else {
             $('#app-alert').fadeOut(2000);
         }
      }    

      
  }

  
