
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/plugins/storage';
import api from '@/plugins/api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import SmartTable from 'vuejs-smart-table';
import { VueMaskFilter } from 'v-mask';
import router from '../../router';
import { VueEditor, Quill } from 'vue2-editor';
import toaster from '@/components/Toasters/Toasters.vue';
import { compress } from 'lz-string';
import { ApplicationForm, GrantStatus, Note, UploadedDocument } from '../../models/main-models';
import moment from 'moment';
import { Methods } from '@/utils/methods';

const $ = require('jquery');

var fs = require('fs');

Vue.filter('VMask', VueMaskFilter);
extend('required', required);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    VueMaskFilter,
    VueEditor,
    Quill,
    toaster,
  },
})
export default class appForm extends Vue {
  private statusList: GrantStatus[] = [];
  private app: ApplicationForm = {} as ApplicationForm;
  private notes: Note[] = [];
  private documents: UploadedDocument[] = [];
  private moment = moment;
  private methods = Methods;
  private user: any = {};
  private noteText: string = '';
  private showConfirmDeleteNote: boolean = false;
  private showConfirmDeleteDoc: boolean = false;
  private docForDeletion: any = {};
  private noteForDeletion: any = {};
  private areaReps: any = [];
  private selectedAreaRep: any = {};
  private adminList: any = {};
  private dmaList: any = [];
  private contactList: any = [];
  private role: string = '';
  private downloadError: string = '';

  private async created() {
    //page loaded, do stuff here
    $('#app-loading').fadeIn(1000);
    try {
      this.user = JSON.parse(storage.local.getItem('user') || '{}');
      if (this.user.id == null || this.user.id == undefined) {
        router.push({ name: 'Login' }).catch((e) => {});
      }

      var id = parseInt(this.$route.params.id, 10);
      var response = await api.getAdminApplication(id, this.user.id);

      this.app = response.application;
      this.statusList = await api.getGrantStatusList();
      this.adminList = await api.getAdminUserList();
      this.documents = response.documents;
      this.notes = response.notes;

      this.role = this.user.role;

      this.dmaList = await api.getDMAs();
      this.contactList = ['NO', 'YES'];
      this.areaReps = await api.getAreaReps();

      $('#app-loading').fadeOut(1000);
    } catch (e) {
      $('#app-loading').fadeOut(1000);
    }
  }

  private async OnSaveModificationClicked() {
    this.app.updatedBy = this.user.id;

    const obj = {
      application: this.app,
      notes: [] as Note[],
    };

    if (this.noteText) {
      obj.notes.push(new Note(this.notes ? this.notes.length + 1 : 1, this.noteText, this.user.id, this.app.appId, this.user.firstName + ' ' + this.user.lastName));
      this.noteText = '';
    }

    try {
      const response = await api.saveModifiedAplication(obj);
      this.notes = response.notes;
      this.app = response.application;
    } catch (error) {
      this.showhideAlert(true, 'An error occurred while saving the modification...', true, 'error');
    }
  }

  private async removeNote(note: any) {
    //show a confirm delete
    this.showConfirmDeleteNote = true;
    this.showConfirmDeleteDoc = false;
    this.noteForDeletion = note;
    $('#form-confirm-dialog').modal('show');
  }

  private showhideAlert(showAlert: any, msgText: any, showLoading: any, status: any) {
    $('#app-alert').removeClass('d-none');
    if (status == 'error') {
      $('#app-alert').addClass('alert-danger');
      $('#app-alert').removeClass('alert-success');
    } else {
      $('#app-alert').addClass('alert-success');
      $('#app-alert').removeClass('alert-danger');
    }
    if (showAlert == true) {
      if (showLoading == false) {
        $('#app-alert-spinner').addClass('d-none');
      } else {
        $('#app-alert-spinner').removeClass('d-none');
      }
      $('#app-alert-msg').html(msgText);
      $('#app-alert').fadeIn(1000);
      setTimeout(function () {
        $('#app-alert').fadeOut(2000);
      }, 3000);
    } else {
      $('#app-alert').fadeOut(2000);
    }
  }

  private async viewVideoPDFDocument() {
    try {
      //only works in CHROME AND FIREFOX
      var nAgt = navigator.userAgent;
      var filecontentURL = process.env.VUE_APP_API_URL + '/api/app/video-pdf?appId=' + this.app.appId; //await api.getAppPDF(this.appId);

      var a = window.document.createElement('a');
      a.href = filecontentURL;
      a.download = 'Application.pdf';
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    } catch (ex) {
      this.downloadError = 'Unexpected Error Occured while attempting to download the application PDF, please contact support.';
    }
  }

  private async downloadDocument(doc: any) {
    try {
      var content = await api.getDocumentContent(doc);
      doc.content = content;

      var bin = atob(doc.content);
      var ab = this.s2ab(bin); // from example above

      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([ab], { type: doc.contentType }));
      a.download = doc.fileName;
      // Append anchor to body.
      document.body.appendChild(a);
      a.click();
      // Remove anchor from body
      document.body.removeChild(a);
    } catch (ex) {
      this.downloadError = 'Unexpected Error Occured while attempting to download the document, please contact support.';
    }
  }

  private async viewDocument(doc: any) {
    try {
      var content = await api.getDocumentContent(doc);
      doc.content = content;

      //only works in CHROME AND FIREFOX
      var nAgt = navigator.userAgent;
      var filecontent = 'data:' + doc.contentType + ';base64,' + doc.content;
      var img = '<img  src="' + filecontent + '" style="text-align:center;" />';
      var pdf =
        '<object data="' +
        filecontent +
        '" type="application/pdf" width="100%" height="100%">PDF plugin for this browser is not detected. <a href="' +
        filecontent +
        '"> Click here to download the Code of Conduct PDF file. </a></object>';

      // if(doc.content.length > 20000000){
      //     this.showhideAlert(true, 'File exceeded size for viewing (20mb)... ', false, 'error');
      //     api.downloadFile(doc);
      //     return;
      // }

      if ((doc.contentType.includes('pdf') || doc.contentType.includes('image')) && nAgt.indexOf('Edge') <= -1) {
        var x = window.open(filecontent);
        if (x) {
          x.document.open();
          x.document.title = doc.fileName;
          x.document.write(doc.contentType.includes('pdf') ? pdf : img);
          x.document.close();
        }
      }
      //Excel and Word docs
      if (doc.contentType.includes('sheet') || doc.contentType.includes('doc') || nAgt.indexOf('Edge') > -1 || doc.documentType === 2) {
        var bin = atob(doc.content);
        var ab = this.s2ab(bin); // from example above
        var byteArray = new Uint8Array(doc.BinaryData);
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([ab], { type: doc.contentType }));
        a.download = doc.fileName;
        // Append anchor to body.
        document.body.appendChild(a);
        a.click();
        // Remove anchor from body
        document.body.removeChild(a);
      }
    } catch (ex) {
      this.downloadError = 'Unexpected Error Occured while attempting to download the document, please contact support.';
    }
  }

  private s2ab(s: any) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  private async removeDocument(doc: any) {
    //show a confirm delete
    this.showConfirmDeleteDoc = true;
    this.showConfirmDeleteNote = false;
    this.docForDeletion = doc;
    $('#form-confirm-dialog').modal('show');
  }

  private async confirmDeleteDocClicked() {
    this.showhideAlert(true, 'Saving...', false, 'success');
    try {
      //save api call doc delete
      $('#form-confirm-dialog').modal('hide');
      this.showConfirmDeleteNote = false;
      await api.removeDocument(this.docForDeletion);
      this.documents.splice(this.documents.indexOf(this.docForDeletion), 1);
      this.showhideAlert(true, 'Document deleted successfully!', false, 'success');
    } catch (e) {
      this.showhideAlert(true, 'An error occurred while deleting the document...', true, 'error');
    }
  }

  private async confirmDeleteNoteClicked() {
    this.showhideAlert(true, 'Saving...', false, 'success');
    try {
      //save api call doc delete
      $('#form-confirm-dialog').modal('hide');
      await api.removeAppNote(this.noteForDeletion);
      this.notes.splice(this.notes.indexOf(this.noteForDeletion), 1);
      this.showhideAlert(true, 'Note deleted successfully!', false, 'success');
    } catch (e) {
      this.showhideAlert(true, 'An error occurred while deleting the note...', true, 'error');
    }
  }
}
