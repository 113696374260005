
import { Component, Prop, Vue } from "vue-property-decorator";
import router from "@/router";
import storage from '@/plugins/storage'
import { EventBus } from '@/plugins/eventbus';
import store from "@/store";
import api from '@/plugins/api';


@Component({  
})
export default class TopNavLayout extends Vue {
     private user: any = {};
     private prequafLink : string = ( process.env.VUE_APP_ENVIRON == 'CA' ? 'prequalify-ca' : 'prequalify');
     private isDisable : Boolean = false; 

      private appLimit: any  = {
              seasonOpen : false,
              limitCapMet : false,
              appLimitNum : '600',
              seasonalMessage : ''
            };

      private schedule : any = {
        openDate : '',
        closedDate : '',
        applicationLimit : 25,
        currentAppplications : 18,
        seasonalMessage : "",
        deferApps : false,
        seasonOpen: false,
        limitMet: false
      }  

      private async created() {
         //page loaded, do stuff here  
           this.user = JSON.parse(storage.local.getItem('user') || '{}');  

           this.appLimit = await api.getSeasonalLimit();   

           this.schedule = await api.getSchedule();

           var convertDate = new Date(this.schedule.openDate);

           this.schedule.openDate = convertDate.toLocaleDateString('en-US');

           if(this.appLimit.seasonOpen == false || this.appLimit.limitCapMet == true){
             this.isDisable = true;
           }

            EventBus.$on('user-logged-in', this.UserLoggedIn);
      }    

      private async LoginClicked() {          
          router.push({path: '/login'}).catch(e => {});
      }

      private async UserLoggedIn() {          
          this.user = JSON.parse(storage.local.getItem('user') || '{}');  
      }

      private async LogoutClicked() {
          store.dispatch('auth/clearToken');  
          storage.local.setItem("user", JSON.stringify(''|| {}));       
          router.push({name: "Home"});
          this.user = {};
      }    
  
}
