 
  import { Component, Vue } from 'vue-property-decorator'
  import storage from '@/plugins/storage'
  import { EventBus } from '@/plugins/eventbus';
  import api from '@/plugins/api'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules'; 
  import router from "@/router"; 

  const $ = require('jquery');

 extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
 });
 extend('email', email);

 @Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
  })
  export default class appReq1Form extends Vue {
      private user: any = {};
      private states: any = [];
      private errorMsg : string = '';
      private validationMsg : string = '';
      private openDialog: Boolean = false ;
       
      private showGrantType1Questions : boolean = false;
      private showGrantType2Questions : boolean = false;     

      private app: any = {
          //applicant
          appId: 0, 
          //request
          grantRequestType :  1,
          isRequestCompleted : false,
          requestInformation:  '',
          vendorCompanyName:  '',
          salesRepName:  '',
          salesRepEmail:  '',
          requestFundingAmt: 0,
          confirmFundingAmt:  '',
          requestDetails: '',  
          acceptFundingVarianceInd: null,
          previousFundingInd : null,
          nbrOfTimesPrevSubmitted :  null, 
          nbrofChildrenImpacted : null,
          nbrofSeniorsImpacted : null,
          nbrOfScholarships : null,
          appliedInThePast : null   
      };    
     
       private async created() {
           //get user           
           this.user = JSON.parse(storage.local.getItem('user') || '{}');       
        
           //Load Applicant Info            
           if (this.$route.params.id != 'new') {
               var id = parseInt(this.$route.params.id, 10);
               this.app = await api.getApplication(id, this.user.id);   
            }
            else {
               window.location.href = "/my-apps";
            }   

           // Listen for the i-got-clicked event and its payload.
          EventBus.$on('app-request-1-saveclicked', this.SaveClicked);
          EventBus.$on('app-request-1-submitclicked', this.SubmitClicked);
      }  

     public async SubmitClicked(id: number) {         
          try {            
            var valid = await (this.$refs.app1Form as Vue & { validate: () => boolean }).validate()                        
            if (!valid) {
              return;
            }                   
              
             //validate the funding amount
             if ((this.app.requestFundingAmt <= 0 || this.app.requestFundingAmt == null ) || this.app.requestFundingAmt > 50000) {
                this.validationMsg = 'Funding Amount must be greater than zero and less than $50,000.';
                $("input[name='requestFundingAmt']").focus();
                $("input[name='requestFundingAmt']").addClass('is-invalid'); 
                this.openDialog = true;
                return;
             } 
             
             if (this.app.acceptFundingVarianceInd != 1 ) {
                this.validationMsg = 'Acceptance of Funding Variance is required.';    
                 $("input[name='AcceptFundingVarianceInd']").focus(); 
                 $("input[name='AcceptFundingVarianceInd']").addClass('is-invalid');           
                this.openDialog = true;
                return;
             }        

             this.app.grantRequestType = 1;
             this.app.appliedInThePast = parseInt(this.app.appliedInThePast);
             this.app.acceptFundingVarianceInd = 1;           

            const appResponse = await api.saveApplicationRequest(this.app);               
            if (appResponse) {               
                 if (appResponse.appId > 0){                                      
                      router.push({name: "ApplicationCommunity", params: { id: this.$route.params.id } });                      
                  }
                  else {
                     //display a successful toaster                     
                    // $('#toast-success').removeClass('d-none').toast('show');        
                  }   
            }   
          } catch (e) {
             //$('#toast-success').removeClass('d-none').toast('show');                
          }   
      }

      public async SaveClicked(id: number) {         
          var valid = await (this.$refs.app1Form as Vue & { validate: () => boolean }).validate()                        
          if (!valid) { 
              return;
          } 

           //validate the funding amount
             if ((this.app.requestFundingAmt <= 0 || this.app.requestFundingAmt == null ) || this.app.requestFundingAmt > 50000) {
                this.validationMsg = 'Funding Amount must be greater than zero and less than $50,000.';
                $("input[name='requestFundingAmt']").focus();
                $("input[name='requestFundingAmt']").addClass('is-invalid');  
                this.openDialog = true;
                return;
             }  
             
             if (this.app.acceptFundingVarianceInd != 1 ) {
                this.validationMsg = 'Acceptance of Funding Variance is required.';    
                 $("input[name='AcceptFundingVarianceInd']").focus();
                 $("input[name='AcceptFundingVarianceInd']").addClass('is-invalid');              
                this.openDialog = true;
                return;
             }        

          this.app.grantRequestType = 1;
          this.app.appliedInThePast = parseInt(this.app.appliedInThePast);
          this.app.acceptFundingVarianceInd = 1;

          try {  
            const appResponse = await api.saveApplicationRequest(this.app);               
            if (appResponse) {               
                 if (appResponse.appId <= 0){  
                      $('#toast-success').removeClass('d-none').toast('show');             
                  } 
                  //display a successful toaster
                  $('#toast-success-msg').html('Request Information saved successfully!'); 
                  $('#toast-success').removeClass('d-none').toast('show'); 
            }   
          } catch (e) {      
            $('#toast-success').removeClass('d-none').toast('show');               
          }  
      }

      private async BackClicked() {          
          router.push({name: "ApplicationDocuments", params: { id: this.$route.params.id } })   
      }
 
      private async grantType1Clicked() {
           this.showGrantType1Questions = true;
           this.showGrantType2Questions = false;
      }  

      private async grantType2Clicked() {
           this.showGrantType1Questions = false;
           this.showGrantType2Questions = true;
      }  

      private onlyNumeric(e : any) {
         e.target.value = e.target.value.replace(/[^0-9]+/g, '')
      }
     

  }

  
