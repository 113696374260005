
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/plugins/storage';
import api from '@/plugins/api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import router from '@/router';
const $ = require('jquery');

extend('required', required);

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class appForm extends Vue {
  private user: any = {};
  private states: any = [];
  private Documents: any = {};
  private errorMsg: string = '';

  private contentHTML: string = '';
  private isLoading: Boolean = true;

  private grantRequestType: string = '';

  private equipmentPositiveEffectsError: boolean = false;
  private equipmentPositiveEffectsErrormsg: string = '';

  private communityPositiveEffectsError: boolean = false;
  private communityPositiveEffectsErrormsg: string = '';

  private equipmentImpactInstanceError: boolean = false;
  private equipmentImpactInstanceErrormsg: string = '';

  private appId: number = 0;
  private app: any = {
    appId: '',
    communityPositiveEffects: '',
    equipmentImpactInstance: '',
    equipmentPositiveEffects: '',
    isCommunityCompleted: false,
    isSubmitted: false,
  };

  private appLimit: any = {
    seasonOpen: false,
    limitCapMet: false,
    appLimitNum: '600',
    seasonalMessage: '',
  };

  private openDialog: Boolean = false;

  private async created() {
    //get user
    this.user = JSON.parse(storage.local.getItem('user') || '{}');

    //Load Applicant Info
    if (this.$route.params.id != 'new') {
      var id = parseInt(this.$route.params.id, 10);
      this.app = await api.getApplication(id, this.user.id);
      if (this.app.isSubmitted == true) {
        router.push({ name: 'LoginConfirm' }).catch((e) => {});
      }
    }

    var type = await api.getApplication(this.app.appId, this.user.id);
    this.grantRequestType = type.grantRequestTypeDisplay;

    const Response = await api.getSiteContent('APPLICATON_DOCUMENTS');
    this.contentHTML = Response.content;
    this.isLoading = false;
  }

  private isFormValid() {
    var bValid = true;
    if (this.app.communityPositiveEffects == null || this.app.communityPositiveEffects.length <= 0) {
      this.communityPositiveEffectsError = true;
      this.communityPositiveEffectsErrormsg = 'Community Positive Effects is required!';
      $('input[name="communityPositiveEffects"]').addClass('is-invalid');
      $('input[name="communityPositiveEffects"]').focus();
      return false;
    } else {
      this.communityPositiveEffectsError = false;
      this.communityPositiveEffectsErrormsg = '';
      $('input[name="communityPositiveEffects"]').removeClass('is-invalid');

      if (this.app.equipmentPositiveEffects == null && this.app.equipmentImpactInstance == null && this.app.communityPositiveEffects != null) {
        return true;
      }
    }

    if (this.app.equipmentImpactInstance == null || this.app.equipmentImpactInstance.length <= 0) {
      this.equipmentImpactInstanceError = true;
      this.equipmentImpactInstanceErrormsg = 'Equipment Impact Instance is Required!';
      $('input[name="equipmentImpactInstance"]').addClass('is-invalid');
      $('input[name="equipmentImpactInstance"]').focus();
      return false;
    } else {
      this.equipmentImpactInstanceError = false;
      this.equipmentImpactInstanceErrormsg = '';
      $('input[name="equipmentImpactInstance"]').removeClass('is-invalid');
    }

    if (this.app.equipmentPositiveEffects == null || this.app.equipmentPositiveEffects.length <= 0) {
      this.equipmentPositiveEffectsError = true;
      this.equipmentPositiveEffectsErrormsg = 'Equipment Positive Effects Required!';
      $('input[name="equipmentPositiveEffects"]').addClass('is-invalid');
      $('input[name="equipmentPositiveEffects"]').focus();
      return false;
    } else {
      this.equipmentPositiveEffectsError = false;
      this.equipmentPositiveEffectsErrormsg = '';
      $('input[name="equipmentPositiveEffects"]').removeClass('is-invalid');
    }

    return bValid;
  }

  private async SubmitClicked() {
    if (!this.isFormValid()) return;

    this.appLimit = await api.getSeasonalLimit();

    if (this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false) {
      $('#form-limit-dialog').modal('show');
      return;
    }

    try {
      var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate();
      if (!valid) {
        return;
      }

      const appResponse = await api.saveApplicationCommunity(this.app);
      if (appResponse) {
        if (appResponse.appId > 0) {
          //Need to let parent know to move to next page **Would be good to save to the parent screen (Applicaton)
          router.push({ name: 'ApplicationFranchise', params: { id: appResponse.appId } }).catch((e) => {});
        } else {
          $('#toast-success').removeClass('d-none').toast('show');
        }
      }
    } catch (e) {
      $('#toast-success').removeClass('d-none').toast('show');
    }
  }

  private async SaveClicked() {
    if (!this.isFormValid()) return;

    try {
      var valid = await (this.$refs.appForm as Vue & { validate: () => boolean }).validate();
      if (!valid) {
        return;
      }

      const appResponse = await api.saveApplicationCommunity(this.app);
      if (appResponse) {
        if (appResponse.appId > 0) {
          //display a successful toaster
          $('#toast-success-msg').html('Community Information saved successfully!');
          $('#toast-success').removeClass('d-none').toast('show');
        } else {
          $('#toast-success').removeClass('d-none').toast('show');
        }
      }
    } catch (e) {
      $('#toast-success').removeClass('d-none').toast('show');
    }
  }

  private async BackClicked() {
    router.push({ name: 'ApplicationRequest', params: { id: this.$route.params.id } }).catch((e) => {});
  }
}
