
import { Component, Prop, Vue } from "vue-property-decorator";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/views/Layout/MobileMenu.vue";
import toaster from '@/components/Toasters/Toasters.vue';

 const $ = require('jquery');

@Component({
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    toaster
    //MobileMenu
  }
})
export default class DashboardLayout extends Vue {
   private async created() {
        

     }  

}
