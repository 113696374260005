
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/plugins/storage';
import api from '@/plugins/api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import router from '@/router';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
const $ = require('jquery');

extend('required', required);

@Component({
  components: {
    ValidationProvider,
    PulseLoader,
    ValidationObserver,
  },
})
export default class appPg2Form extends Vue {
  private user: any = {};
  private states: any = [];
  private Documents: any = {};
  private errorMsg: string = '';

  private contentHTML: string = '';
  private isLoading: Boolean = true;
  private isW9: Boolean = false;
  private isFinancialFileLoading: Boolean = false;
  private isEquipFile: Boolean = false;
  private isVendorFile: Boolean = false;
  private isBackgroundFile: Boolean = false;

  private appId: number = 0;
  private fileBackgroundId: number = 0;
  private fileBackgroundContent: string = '';
  private fileBackgroundContentType: string = '';
  private fileBackgroundFileName: string = '';
  private fileBackgroundErrormsg: string = '';
  private fileBackgroundError: boolean = false;
  private fileBackgroundDocType: number = 6;

  private fileVendorId: number = 0;
  private fileVendorContent: string = '';
  private fileVendorContentType: string = '';
  private fileVendorFileName: string = '';
  private fileVendorErrormsg: string = '';
  private fileVendorError: boolean = false;
  private fileVendorDocType: number = 1;

  private fileFinancialId: number = 0;
  private fileFinancialContent: string = '';
  private fileFinancialContentType: string = '';
  private fileFinancialFileName: string = '';
  private fileFinancialErrormsg: string = '';
  private fileFinancialError: boolean = false;
  private fileFinancialDocType: number = 2;

  private fileW9Id: number = 0;
  private FileW9Name: string = '';
  private fileW9Content: string = '';
  private fileW9ContentType: string = '';
  private fileW9DocType: number = 7;
  private fileW9Errormsg: string = '';
  private fileW9Error: boolean = false;

  private fileEquipId: number = 0;
  private fileEquipContent: string = '';
  private fileEquipContentType: string = '';
  private fileEquipFileName: string = '';
  private fileEquipErrormsg: string = '';
  private fileEquipError: boolean = false;
  private fileEquipDocType: number = 4;
  private IsDocumentsCompleted: boolean = false;
  private isSubmitted: boolean = false;

  private appLimit: any = {
    seasonOpen: false,
    limitCapMet: false,
    appLimitNum: '600',
    seasonalMessage: '',
  };

  private async created() {
    //page loaded, do stuff here
    this.user = JSON.parse(storage.local.getItem('user') || '{}');

    const Response = await api.getSiteContent('APPLICATON_DOCUMENTS');
    this.contentHTML = Response.content;

    //load documents
    this.appId = parseInt(this.$route.params.id, 10);
    var app = await api.getApplication(this.appId, this.user.id);
    if (app.isSubmitted == true) {
      router.push({ name: 'LoginConfirm' }).catch((e) => {});
    }

    this.LoadDocuments();

    this.isLoading = false;
  }

  private async SubmitClicked() {
    this.appLimit = await api.getSeasonalLimit();
    if (this.appLimit.limitCapMet == true || this.appLimit.seasonOpen == false) {
      $('#form-limit-dialog').modal('show');
      return;
    }

    if (!this.isFormValid()) return;
    this.PersistSave(true);
  }

  private async SaveClicked() {
    if (!this.isFormValid()) return;
    this.PersistSave(false);
  }

  private async LoadDocuments() {
    if (this.appId <= 0) {
      return;
    }
    const appDocs = await api.getApplicationDocuments(this.appId, this.user.id);
    if (appDocs && appDocs.documents) {
      for (var i = 0; i < appDocs.documents.length; i++) {
        //add the background document
        if (appDocs.documents[i].documentType == this.fileBackgroundDocType) {
          this.fileBackgroundFileName = appDocs.documents[i].fileName;
          this.fileBackgroundContent = appDocs.documents[i].content;
          this.fileBackgroundContentType = appDocs.documents[i].contentType;
        }
        //add the vendor document
        if (appDocs.documents[i].documentType == this.fileVendorDocType) {
          this.fileVendorFileName = appDocs.documents[i].fileName;
          this.fileVendorContent = appDocs.documents[i].content;
          this.fileVendorContentType = appDocs.documents[i].contentType;
        }
        //add the Financial document
        if (appDocs.documents[i].documentType == this.fileFinancialDocType) {
          this.fileFinancialFileName = appDocs.documents[i].fileName;
          this.fileFinancialContent = appDocs.documents[i].content;
          this.fileFinancialContentType = appDocs.documents[i].contentType;
        }
        //add the Equip document
        if (appDocs.documents[i].documentType == this.fileEquipDocType) {
          this.fileVendorId = appDocs.documents[i].Id;
          this.fileEquipFileName = appDocs.documents[i].fileName;
          this.fileEquipContent = appDocs.documents[i].content;
          this.fileEquipContentType = appDocs.documents[i].contentType;
        }
        //add the W9 document
        if (appDocs.documents[i].documentType == this.fileW9DocType) {
          this.FileW9Name = appDocs.documents[i].fileName;
          this.fileW9Content = appDocs.documents[i].content;
          this.fileW9ContentType = appDocs.documents[i].contentType;
        }
      }
      this.IsDocumentsCompleted = appDocs.application.isDocumentComplete;
      this.isSubmitted = appDocs.application.isSubmitted;
    }
  }

  private async PersistSave(redirectNext: any) {
    if (!this.isFormValid()) return;

    this.isLoading = true;

    // var appDocs = {
    //    documents : [{}],
    //    application : {
    //      appId : 0,
    //      isDocumentCompleted: false,
    //      isSubmitted: false
    //    }
    // }

    // //add the background document
    // var doc1 = {
    //    Id : this.fileBackgroundId,
    //    documentType : this.fileBackgroundDocType,
    //    fileName : this.fileBackgroundFileName,
    //    Content : this.fileBackgroundContent,
    //    ContentType : this.fileBackgroundContentType
    // }
    // doc1.documentType = this.fileBackgroundDocType;
    // doc1.fileName = this.fileBackgroundFileName;
    // doc1.Content = (this.fileBackgroundContent.split("base64,")[1] == null ? this.fileBackgroundContent : this.fileBackgroundContent.split("base64,")[1] );
    // doc1.ContentType = this.fileBackgroundContentType;
    // appDocs.documents.push(doc1);

    // //add the vendor document
    // var doc2 = {
    //    Id : this.fileVendorId,
    //    documentType : this.fileVendorDocType,
    //    fileName : this.fileVendorFileName,
    //    Content : (this.fileVendorContent.split("base64,")[1] == null ? this.fileVendorContent : this.fileVendorContent.split("base64,")[1] ),
    //    ContentType : this.fileVendorContentType
    // }
    // appDocs.documents.push(doc2);

    // //add the Financial document
    // var doc3 = {
    //    Id : this.fileFinancialId,
    //    documentType : this.fileFinancialDocType,
    //    fileName : this.fileFinancialFileName,
    //    Content : (this.fileFinancialContent.split("base64,")[1] == null ? this.fileFinancialContent : this.fileFinancialContent.split("base64,")[1] ),
    //    ContentType : this.fileFinancialContentType
    // }
    // appDocs.documents.push(doc3);

    //  //add the Equipment document
    //   var doc4 = {
    //    Id : this.fileFinancialId,
    //    documentType : this.fileEquipDocType,
    //    fileName : this.fileEquipFileName,
    //    Content : (this.fileEquipContent.split("base64,")[1] == null ? this.fileEquipContent : this.fileEquipContent.split("base64,")[1] ),
    //    ContentType : this.fileEquipContentType
    // }
    // appDocs.documents.push(doc4);
    // appDocs.documents.shift();
    // appDocs.application.appId = this.appId;

    // //add the W9 document
    // var doc5 = {
    //    Id : this.fileW9Id,
    //    documentType : this.fileW9DocType,
    //    fileName : this.FileW9Name,
    //    Content : this.fileW9Content,
    //    ContentType : this.fileW9ContentType
    // }
    //  doc5.documentType = this.fileW9DocType;
    //  doc5.fileName = this.FileW9Name;
    //  doc5.Content = (this.fileW9Content.split("base64,")[1] == null ? this.fileW9Content : this.fileW9Content.split("base64,")[1] );
    // // doc5.ContentType = this.fileW9ContentType;
    // appDocs.documents.push(doc5);

    var paramId = String(this.appId);

    router.push({ name: 'ApplicationRequest', params: { id: paramId } });

    //create the document model / application Id
    // try {
    //   const appResponse = await api.saveApplicationDocuments(appDocs);
    //   if (appResponse) {
    //        if (appResponse.application.isDocumentComplete == true){
    //              this.IsDocumentsCompleted = appResponse.application.isDocumentComplete;
    //              if (redirectNext == true) {
    //                 router.push({name: "ApplicationRequest", params: { id: appResponse.application.appId } }).catch(e => {});
    //              }
    //              else {
    //                 //Need to show Toaster save completed
    //                 $('#toast-success-msg').html('Documents saved successfully!');
    //                 $('#toast-success').removeClass('d-none').toast('show');
    //              }
    //         }
    //         else {
    //             $('#toast-success').removeClass('d-none').toast('show');
    //         }
    //   }
    // } catch (e) {
    //     $('#toast-success').removeClass('d-none').toast('show');
    // }
  }

  private async BackClicked() {
    window.location.href = '/application/' + (this.appId > 0 ? this.appId : 'new') + '/applicant';
  }

  private isFormValid() {
    var bValid = true;
    if (this.fileBackgroundFileName.length <= 0) {
      this.fileBackgroundError = true;
      this.fileBackgroundErrormsg = 'Background/History is required!';
      $('input[name="fileBackgroundFileName"]').addClass('is-invalid');
      $('input[name="fileBackgroundFileName"]').focus();
      return false;
    } else {
      this.fileBackgroundError = false;
      this.fileBackgroundErrormsg = '';
      $('input[name="fileBackgroundFileName"]').removeClass('is-invalid');
    }

    if (this.fileVendorFileName.length <= 0) {
      this.fileVendorError = true;
      this.fileVendorErrormsg = 'Vendor Equipment Quote/Bid is required!';
      $('input[name="fileVendorFileName"]').addClass('is-invalid');
      $('input[name="fileVendorFileName"]').focus();
      return false;
    } else {
      this.fileVendorError = false;
      this.fileVendorErrormsg = '';
      $('input[name="fileVendorFileName"]').removeClass('is-invalid');
    }

    if (this.fileFinancialFileName.length <= 0) {
      this.fileFinancialError = true;
      this.fileFinancialErrormsg = 'Most Recent Financial Information is required!';
      $('input[name="fileFinancialFileName"]').addClass('is-invalid');
      $('input[name="fileFinancialFileName"]').focus();
      return false;
    } else {
      this.fileFinancialError = false;
      this.fileFinancialErrormsg = '';
      $('input[name="fileFinancialFileName"]').removeClass('is-invalid');
    }

    if (this.fileEquipFileName.length <= 0) {
      this.fileEquipError = true;
      this.fileEquipErrormsg = 'Equipment Inventory is required!';
      $('input[name="fileEquipFileName"]').addClass('is-invalid');
      $('input[name="fileEquipFileName"]').focus();
      return false;
    } else {
      this.fileEquipError = false;
      this.fileEquipErrormsg = '';
      $('input[name="fileEquipFileName"]').removeClass('is-invalid');
    }

    if (this.FileW9Name.length <= 0) {
      this.fileW9Error = true;
      this.fileW9Errormsg = 'W-9 is required!';
      $('input[name="fileW9Name"]').addClass('is-invalid');
      $('input[name="fileW9Name"]').focus();
      return false;
    } else {
      this.fileW9Error = false;
      this.fileW9Errormsg = '';
      $('input[name="fileW9Name"]').removeClass('is-invalid');
    }

    return bValid;
  }

  private async handleBackgroundFileChange(e: any) {
    this.isBackgroundFile = true;
    var files = e.target.files;
    if (!files.length) return;

    var BackgroundFileDoc = {
      documents: [{}],
      application: {
        appId: 0,
        isDocumentCompleted: false,
        isSubmitted: false,
      },
    };

    const fsize = files[0].size;
    const file = Math.round(fsize / 1024);
    if (file > 20000) {
      e.preventDefault();
      this.isBackgroundFile = false;
      this.fileBackgroundError = true;
      this.fileBackgroundFileName = '';
      this.fileBackgroundContentType = '';
      this.fileBackgroundErrormsg = 'File Size Invalid.. Please select a file with less than 20MB in size.';
      return;
    }

    const fileName = files[0].name.toLowerCase();
    //check file types ext:jpg,doc,docx,jpeg,xlsx
    if (
      !fileName.includes('jpg') &&
      !fileName.includes('png') &&
      !fileName.includes('jpeg') &&
      !fileName.includes('doc') &&
      !fileName.includes('xls') &&
      !fileName.includes('pdf')
    ) {
      e.preventDefault();
      this.isBackgroundFile = false;
      this.fileBackgroundError = true;
      this.fileBackgroundFileName = '';
      this.fileBackgroundContentType = '';
      this.fileBackgroundErrormsg = 'File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX).';
      $('input[name="fileBackgroundFileName"]').addClass('is-invalid');
      return;
    }
    this.fileBackgroundFileName = files[0].name;
    this.fileBackgroundContentType = files[0].type;
    this.fileBackgroundError = false;
    this.fileBackgroundErrormsg = '';
    $('input[name="fileBackgroundFileName"]').removeClass('is-invalid');
    // Pass getBuffer to promise.
    //this.readFileData(files[0], "Background");

    var content: string = await this.readFileContent(files[0], 'Background');
    this.fileBackgroundContent = content;

    var doc = {
      Id: this.fileBackgroundId,
      documentType: this.fileBackgroundDocType,
      fileName: this.fileBackgroundFileName,
      Content: this.fileBackgroundContent.split('base64,')[1] == null ? this.fileBackgroundContent : this.fileBackgroundContent.split('base64,')[1],
      ContentType: this.fileBackgroundContentType,
    };

    BackgroundFileDoc.documents.shift();
    BackgroundFileDoc.documents.push(doc);
    BackgroundFileDoc.application.appId = this.appId;

    this.isBackgroundFile = false;
    const appResponse = await api.saveApplicationDocuments(BackgroundFileDoc);
  }

  private async handleVendorFileChange(e: any) {
    this.isVendorFile = true;
    var files = e.target.files;
    if (!files.length) return;

    var VendorFileDoc = {
      documents: [{}],
      application: {
        appId: 0,
        isDocumentCompleted: false,
        isSubmitted: false,
      },
    };

    const fsize = files[0].size;
    const file = Math.round(fsize / 1024);
    if (file > 20000) {
      e.preventDefault();
      this.isVendorFile = false;
      this.fileVendorError = true;
      this.fileVendorFileName = '';
      this.fileVendorContentType = '';
      this.fileVendorErrormsg = 'File Size Invalid.. Please select a file with less than 20MB in size.';
      return;
    }

    var fileName = files[0].name.toLowerCase();
    //check file types ext:jpg,doc,docx,jpeg,xlsx
    if (
      !fileName.includes('jpg') &&
      !fileName.includes('png') &&
      !fileName.includes('jpeg') &&
      !fileName.includes('doc') &&
      !fileName.includes('xls') &&
      !fileName.includes('pdf')
    ) {
      e.preventDefault();
      this.isVendorFile = false;
      this.fileVendorError = true;
      this.fileVendorFileName = '';
      this.fileVendorContentType = '';
      this.fileVendorErrormsg = 'File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX).';
      $('input[name="fileVendorFileName"]').addClass('is-invalid');
      return;
    }
    this.fileVendorFileName = files[0].name;
    this.fileVendorContentType = files[0].type;
    this.fileVendorError = false;
    this.fileVendorErrormsg = '';
    $('input[name="fileVendorFileName"]').removeClass('is-invalid');
    // Pass getBuffer to promise.
    //this.readFileData(files[0], "Vendor");

    var content: string = await this.readFileContent(files[0], 'Vendor');
    this.fileVendorContent = content;

    var doc = {
      Id: this.fileVendorId,
      documentType: this.fileVendorDocType,
      fileName: this.fileVendorFileName,
      Content: this.fileVendorContent.split('base64,')[1] == null ? this.fileVendorContent : this.fileVendorContent.split('base64,')[1],
      ContentType: this.fileVendorContentType,
    };

    VendorFileDoc.documents.shift();
    VendorFileDoc.documents.push(doc);
    VendorFileDoc.application.appId = this.appId;

    this.isVendorFile = false;
    const appResponse = await api.saveApplicationDocuments(VendorFileDoc);
  }

  private async handleEquipFileChange(e: any) {
    this.isEquipFile = true;
    var files = e.target.files;
    if (!files.length) return;

    var EquipFileDoc = {
      documents: [{}],
      application: {
        appId: 0,
        isDocumentCompleted: false,
        isSubmitted: false,
      },
    };

    const fsize = files[0].size;
    const file = Math.round(fsize / 1024);
    if (file > 20000) {
      e.preventDefault();
      this.isEquipFile = false;
      this.fileEquipError = true;
      this.fileEquipFileName = '';
      this.fileEquipContentType = '';
      this.fileEquipErrormsg = 'File Size Invalid.. Please select a file with less than 20MB in size.';
      this.isEquipFile = false;
      return;
    }

    var fileName = files[0].name.toLowerCase();
    //check file types ext:jpg,doc,docx,jpeg,xlsx
    if (
      !fileName.includes('jpg') &&
      !fileName.includes('png') &&
      !fileName.includes('jpeg') &&
      !fileName.includes('doc') &&
      !fileName.includes('xls') &&
      !fileName.includes('pdf')
    ) {
      e.preventDefault();
      this.isEquipFile = false;
      this.fileEquipError = true;
      this.fileEquipFileName = '';
      this.fileEquipContentType = '';
      this.fileEquipErrormsg = 'File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX).';
      $('input[name="fileEquipFileName"]').addClass('is-invalid');
      this.isEquipFile = false;
      return;
    }
    this.fileEquipFileName = files[0].name;
    this.fileEquipContentType = files[0].type;
    this.fileEquipError = false;
    this.fileEquipErrormsg = '';
    $('input[name="fileEquipFileName"]').removeClass('is-invalid');
    // Pass getBuffer to promise.
    //this.readFileData(files[0], "Equip");

    var content: string = await this.readFileContent(files[0], 'Equip');
    this.fileEquipContent = content;

    var doc = {
      Id: this.fileEquipId,
      documentType: this.fileEquipDocType,
      fileName: this.fileEquipFileName,
      Content: this.fileEquipContent.split('base64,')[1] == null ? this.fileEquipContent : this.fileEquipContent.split('base64,')[1],
      ContentType: this.fileEquipContentType,
    };

    EquipFileDoc.documents.shift();
    EquipFileDoc.documents.push(doc);
    EquipFileDoc.application.appId = this.appId;

    this.isEquipFile = false;
    const appResponse = await api.saveApplicationDocuments(EquipFileDoc);
  }

  private async handleFinancialFileChange(e: any) {
    this.isFinancialFileLoading = true;
    var files = e.target.files;
    if (!files.length) return;

    var FinancialFileChangeDoc = {
      documents: [{}],
      application: {
        appId: 0,
        isDocumentCompleted: false,
        isSubmitted: false,
      },
    };

    const fsize = files[0].size;
    const file = Math.round(fsize / 1024);
    if (file > 20000) {
      e.preventDefault();
      this.isFinancialFileLoading = false;
      this.fileFinancialError = true;
      this.fileFinancialFileName = '';
      this.fileFinancialContentType = '';
      this.fileFinancialErrormsg = 'File Size Invalid.. Please select a file with less than 20MB in size.';
      this.isFinancialFileLoading = false;
      return;
    }

    var fileName = files[0].name.toLowerCase();
    //check file types ext:jpg,doc,docx,jpeg,xlsx
    if (
      !fileName.includes('jpg') &&
      !fileName.includes('png') &&
      !fileName.includes('jpeg') &&
      !fileName.includes('doc') &&
      !fileName.includes('xls') &&
      !fileName.includes('pdf')
    ) {
      e.preventDefault();
      this.isFinancialFileLoading = false;
      this.fileFinancialError = true;
      this.fileFinancialFileName = '';
      this.fileFinancialContentType = '';
      this.fileFinancialErrormsg = 'File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX).';
      $('input[name="fileFinancialFileName"]').addClass('is-invalid');
      this.isFinancialFileLoading = false;
      return;
    }
    this.fileFinancialFileName = files[0].name;
    this.fileFinancialContentType = files[0].type;
    this.fileFinancialError = false;
    this.fileFinancialErrormsg = '';
    $('input[name="fileFinancialFileName"]').removeClass('is-invalid');
    // Pass getBuffer to promise.
    //this.readFileData(files[0], "Financial");
    var content: string = await this.readFileContent(files[0], 'Financial');
    this.fileFinancialContent = content;

    var doc = {
      Id: this.fileFinancialId,
      documentType: this.fileFinancialDocType,
      fileName: this.fileFinancialFileName,
      Content: this.fileFinancialContent.split('base64,')[1] == null ? this.fileFinancialContent : this.fileFinancialContent.split('base64,')[1],
      ContentType: this.fileFinancialContentType,
    };

    FinancialFileChangeDoc.documents.shift();
    FinancialFileChangeDoc.documents.push(doc);
    FinancialFileChangeDoc.application.appId = this.appId;

    const appResponse = await api.saveApplicationDocuments(FinancialFileChangeDoc);
    this.isFinancialFileLoading = false;
  }

  private async handleW9Change(e: any) {
    this.isW9 = true;
    var files = e.target.files;

    var w9Doc = {
      documents: [{}],
      application: {
        appId: 0,
        isDocumentCompleted: false,
        isSubmitted: false,
      },
    };

    if (!files.length) return;

    const fsize = files[0].size;
    const file = Math.round(fsize / 1024);
    if (file > 20000) {
      e.preventDefault();
      this.isW9 = false;
      this.fileW9Error = true;
      this.FileW9Name = '';
      this.fileW9ContentType = '';
      this.fileW9Errormsg = 'File Size Invalid.. Please select a file with less than 20MB in size.';
      this.isW9 = false;
      return;
    }

    var fileName = files[0].name.toLowerCase();
    //check file types ext:jpg,doc,docx,jpeg,xlsx
    if (
      !fileName.includes('jpg') &&
      !fileName.includes('png') &&
      !fileName.includes('jpeg') &&
      !fileName.includes('doc') &&
      !fileName.includes('xls') &&
      !fileName.includes('pdf')
    ) {
      e.preventDefault();
      this.isW9 = false;
      this.fileW9Error = true;
      this.FileW9Name = '';
      this.fileW9ContentType = '';
      this.fileW9Errormsg = 'File Type Invalid.. Please select a file with a supported file type (PDF, DOCX, JPEG & XLSX).';
      $('input[name="FileW9Name"]').addClass('is-invalid');
      this.isW9 = false;
      return;
    }
    this.FileW9Name = files[0].name;
    this.fileW9ContentType = files[0].type;
    this.fileW9Error = false;
    this.fileW9Errormsg = '';
    $('input[name="FileW9Name"]').removeClass('is-invalid');
    // Pass getBuffer to promise.
    //this.readFileData(files[0], "W9");
    var content: string = await this.readFileContent(files[0], 'W9');
    this.fileW9Content = content;

    var doc = {
      Id: this.fileW9Id,
      documentType: this.fileW9DocType,
      fileName: this.FileW9Name,
      Content: this.fileW9Content.split('base64,')[1] == null ? this.fileW9Content : this.fileW9Content.split('base64,')[1],
      ContentType: this.fileW9ContentType,
    };

    w9Doc.documents.shift();
    w9Doc.documents.push(doc);
    w9Doc.application.appId = this.appId;

    this.isW9 = false;
    const appResponse = await api.saveApplicationDocuments(w9Doc);
  }

  private onlyNumeric(e: any) {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '');
  }

  private readFileContent(file: any, type: string) {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.onerror = (evt) => {
        reject(evt);
      };
    });
  }

  private async readFileData(file: any, type: any) {
    let reader = new FileReader();
    //reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      if (type == 'Background') {
        this.fileBackgroundContent = e.target.result;
      }
      if (type == 'Vendor') {
        this.fileVendorContent = e.target.result;
      }
      if (type == 'Equip') {
        this.fileEquipContent = e.target.result;
      }
      if (type == 'Financial') {
        this.fileFinancialContent = e.target.result;
      }
      if (type == 'W9') {
        this.fileW9Content = e.target.result;
      }
    };
    reader.onerror = (evt) => {
      console.error(evt); //show an alert message here
      return;
    };
    reader.readAsDataURL(file);
  }
}
